<app-navbar></app-navbar>
<div class="container-fluid">
  <br><br><br><br><br><br>
  <div class="text-center">
    <h1>- DISEÑA Y DESARROLLA - <br> TU PRODUCTO</h1>
  </div>
</div>
<div>
  <img src="../../../assets/images/diseña/fondoOtros.png" width="100%" alt="">
</div>
<div class="container-fluid" style="max-width: 90%;">
  <div>
    <img src="../../../assets/images/diseña/titulo.png" width="100%" alt="">
  </div>
  <br>
  <div>
    <img src="../../../assets/images/diseña/contenido.png" width="100%" alt="">
  </div>
  <br><br><br>
  <div>
    <img src="../../../assets/images/diseña/productos.png" width="100%" alt="">
  </div>
</div>
<br><br><br>
<app-footer-desarrollo></app-footer-desarrollo>
