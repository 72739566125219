<app-navbar></app-navbar>
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../assets/images/slider/banner1.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../assets/images/slider/banner2.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../assets/images/slider/banner3.jpg" class="d-block w-100" alt="...">
    </div>
  </div>
 <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </button>
</div>
<div class="container-fluid mt-5">
  <div class="text-center">
    <h1 *ngIf="compuesto">- {{cat | uppercase}} {{subc | uppercase}} -</h1>
    <h1 *ngIf="!compuesto">- {{subc | uppercase}} -</h1>
  </div>
  <br><br>
  <div class="row row-cols-1 row-cols-md-4">
    <div class="col mb-4" *ngFor="let item of listaFiltrada">
      <div class="card h-100" (click)="openProduct(item)">
        <img [src]="item.imagen" class="card-img-top" alt="...">
        <div class="card-body">
          <h4 class="card-title text-center"><b>{{item.producto | titlecase}}</b></h4>
          <p class="text-center">{{item.nombre | titlecase}}</p>
          <img src="../../../assets/images/devider.png" width="100%" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>
<app-footer></app-footer>
