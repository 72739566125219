import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import { UsercontrolService } from 'src/app/services/usercontrol.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solcortinas',
  templateUrl: './solcortinas.component.html',
  styleUrls: ['./solcortinas.component.scss']
})
export class SolcortinasComponent implements OnInit {
  listado: any;
  solicitudSel: any;
  constructor(private prodSvc: ProductosService,
              private userSvc: UsercontrolService) { }

  posicion: any;
  cantidad: any;
  codigo: any;
  precioUni: any;
  descripcion: any;
  prodsel: any;
  userData: any;
  fecha = new Date().getTime();
  ngOnInit(): void {
    this.userData = this.userSvc.usuarioLog;
    console.log(this.userData);    
    this.cargarSolicitudes();
  }

  cargarSolicitudes(){
    this.prodSvc.loadSolicitudesCortinas()
                .subscribe(res => {
                  this.listado = res;
                  console.log(this.listado);                  
                });
  }

  eliminaSolicitud(solicitud: any){
    console.log(solicitud);
    Swal.fire({
      title: 'Está seguro?',
      text: "Se va a eliminar esta solicitud de la base de datos!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.eliminarSolicitudCortinas(solicitud.id).then(() => {
          Swal.fire(
            'Eliminado!',
            'Se ha borrado la solicitud del sistema.',
            'success'
          )
        });       
      }
    })
  }

  detailRequest(solicitud: any){
    console.log(solicitud);  
    this.solicitudSel = solicitud;  
  }

  actualizaProdCotizacion(pos: any, prodsel: any){
     this.posicion = pos;
     this.prodsel = prodsel;
     console.log(prodsel);     
  }

  asignaDatos(){
    this.solicitudSel.productos[this.posicion].cantidad = this.cantidad;
    this.solicitudSel.productos[this.posicion].observacion = this.descripcion;
    this.solicitudSel.productos[this.posicion].codigo = this.codigo;
    this.solicitudSel.productos[this.posicion].preciounidad = this.precioUni;
    this.solicitudSel.productos[this.posicion].total = Number(this.precioUni)*Number(this.cantidad);
    this.solicitudSel.productos[this.posicion].atendido = true;
    this.solicitudSel.asesor = this.userData;
    console.log(this.solicitudSel);    
  }

  cotizar(cotizacion: any){    
    this.calcularCosto(cotizacion);
    console.log(cotizacion);
   cotizacion.fecha = new Date().getTime();
    this.prodSvc.crearCotizacionCortinas(cotizacion).then(() => {
      this.prodSvc.actualizaEstadoSolicitudCortinas(cotizacion.id).then(() => {
        Swal.fire(
          'Hecho',
          'Cotización creada correctamente',
          'success'
        )
      });
    }); 
  }

  calcularCosto(coti: any){
    let cont = 0;
    for (const key in coti.productos) {
      cont = cont + coti.productos[key].total;
    }
    coti.totalcot = cont;
  }

}
