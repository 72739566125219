import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';

@Component({
  selector: 'app-selloslist',
  templateUrl: './selloslist.component.html',
  styleUrls: ['./selloslist.component.scss']
})
export class SelloslistComponent implements OnInit {

  sellos: any;
  sellosel: any;
  nombre = '';
  tipo = '';
  categoria = '';
  orden = '';
  descripcion = '';
  material = '';
  guaya = '';
  aplicacion = '';
  dimensiones = '';
  disponibilidad = '';
  marcacion = '';
  subcategoria: any = [];

  constructor(private prdSvc: ProductosService) { }

  ngOnInit(): void {
    this.prdSvc.listaProductos()
               .subscribe(res => {
                this.sellos = res;
               })
  }

  selloseleccionado(sello: any){
    this.sellosel = sello;   
    console.log(this.sellosel);
    this.nombre = this.sellosel.producto;
    this.tipo =  this.sellosel.nombre;
    this.categoria =  this.sellosel.categoria;
    this.orden =  this.sellosel.orden;
    this.descripcion =  this.sellosel.descripcion;
    this.material =  this.sellosel.material;
    this.guaya =  this.sellosel.tipo;
    this.aplicacion =  this.sellosel.aplicacion;
    this.dimensiones =  this.sellosel.dimensiones;
    this.disponibilidad =  this.sellosel.disponibilidad;
    this.marcacion =  this.sellosel.marcacion;
    
  }

}
