<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 7rem;">
  <p>Cliente: {{cliente.nombre | titlecase}}</p>
  <p>Identificación: {{cliente.documento | titlecase}}. - {{cliente.numero}}</p>
  <p>E-mail: {{cliente.email }}</p>
  <p>Teléfono: {{cliente.telefono }}</p>
  <h3>Seleccione tipo de cotización:</h3>
  <hr>
  <button class="btn btn-primary btn-lg" (click)="seleccionaCotizacion('688')">FAP 687</button>&nbsp;
  <button class="btn btn-success btn-lg" (click)="seleccionaCotizacion('6880')">FAP 687-0</button>
  <br><br>
 <div *ngIf="fap688">
  <div class="text-center">
    <img *ngIf="imagenProdSel" [src]="imagenProdSel" alt="">
  </div>
  <form autocomplete="off" [formGroup]="solicitudForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="card h-100">
          <div class="card-body">
            <select class="form-control" (change)="seleccionSello($event.target.value)">
              <option selected value="">Seleccione sello</option>
              <option *ngFor="let item of sellos" [value]="item.id">{{item.producto | titlecase}}</option>
            </select>
            <br>
            <input type="text" class="form-control" placeholder="Color" formControlName="color">
            <br>
            <input type="text" class="form-control" placeholder="Característica" formControlName="caracteristica">
            <br>
            <input type="text" class="form-control" placeholder="Material" formControlName="material">
            <br>
            <input type="text" class="form-control" placeholder="Marcación" formControlName="marcacion">
            <br>
            <input type="text" class="form-control" placeholder="Guaya" formControlName="guaya">
            <br>
            <input type="number" class="form-control" placeholder="Longitud de la guaya" formControlName="longitud">
            <br>
            <input type="number" class="form-control" placeholder="Cantidad" formControlName="cantidad">
            <br>
        </div>
      </div>
      </div>
      <div class="col-sm-6">
        <div class="card h-100">
          <div class="card-body">
            <input type="number" class="form-control" placeholder="Precio" formControlName="precio">
            <br>
            <input type="text" class="form-control" placeholder="Numeración" formControlName="numeracion">
            <br>
            <input type="text" class="form-control" placeholder="Despacho" formControlName="despacho">
            <br>
            <input type="text" class="form-control" placeholder="Pago" formControlName="pago">
            <br>
            <input type="text" class="form-control" placeholder="Garantía" formControlName="garantia">
            <br>
            <input type="text" class="form-control" placeholder="IVA" formControlName="iva">
            <br>
            <textarea name="" id="" cols="30" rows="5" class="form-control" placeholder="Observaciones" formControlName="observaciones"></textarea>
            <br>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="text-center">
      <button class="btn btn-primary btn-lg" (click)="sendQuote(solicitudForm.value)">Crear Cotización</button>
    </div>
  </form>
 </div>
 <div *ngIf="fap6880">
  <form autocomplete="off" [formGroup]="solicitudFormDos">
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <input type="number" class="form-control" placeholder="Cantidad" formControlName="cantidad">
          <br>
          <input type="text" class="form-control" placeholder="Código" formControlName="codigo">
          <br>

        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <input type="text" class="form-control" placeholder="Producto" formControlName="producto">
          <br>
          <input type="number" class="form-control" placeholder="Precio unidad" formControlName="unidad">
          <br>
        </div>
      </div>
    </div>
  </div>
  </form>
  <br>
  <div class="text-center">
    <button class="btn btn-success btn-lg mr-2" (click)="agregaProducto(solicitudFormDos.value)">Agregar Producto</button>
    <button class="btn btn-primary btn-lg" (click)="enviarCotizacion()">Finalizar Cotización</button>
  </div>
  <br><br>
  <div *ngIf="productosCot.length > 0" class="text-right">
    <h4>Subtotal: ${{subtotal | number}}</h4>
    <h3 style="color: blue;">Total: ${{liquidacion | number}}</h3>
  </div>
  <table *ngIf="productosCot.length > 0" class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Producto</th>
        <th scope="col">Código</th>
        <th scope="col">Cantidad</th>
        <th scope="col">Precio Un</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of productosCot; let i = index">
        <th>{{item.producto | titlecase}}</th>
        <td>{{item.codigo}}</td>
        <td>{{item.cantidad}}</td>
        <td>${{item.unidad | number}}</td>
        <td>
          <button class="btn btn-danger" (click)="borrarProducto(i)"><i class="fas fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
 </div>
</div>
