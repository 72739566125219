import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './crm/admin/admin.component';
import { ClientesComponent } from './crm/clientes/clientes.component';
import { CotcortinasComponent } from './crm/cotcortinas/cotcortinas.component';
import { CotizacionesComponent } from './crm/cotizaciones/cotizaciones.component';
import { NuevaCotizacionComponent } from './crm/nueva-cotizacion/nueva-cotizacion.component';
import { SelloslistComponent } from './crm/selloslist/selloslist.component';
import { SolcortinasComponent } from './crm/solcortinas/solcortinas.component';
import { SolicitudesComponent } from './crm/solicitudes/solicitudes.component';
import { AuthGuard } from './guards/auth.guard';
import { AeronauticaComponent } from './pages/aeronautica/aeronautica.component';
import { CertificacionesComponent } from './pages/certificaciones/certificaciones.component';
import { ConstruccionComponent } from './pages/construccion/construccion.component';
import { CortinasComponent } from './pages/cortinas/cortinas.component';
import { DetalleCortinasComponent } from './pages/detalle-cortinas/detalle-cortinas.component';
import { DisenoProductosComponent } from './pages/diseno-productos/diseno-productos.component';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { EscolaresComponent } from './pages/escolares/escolares.component';
import { ExportacionesComponent } from './pages/exportaciones/exportaciones.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ListasolicitudesComponent } from './pages/listasolicitudes/listasolicitudes.component';
import { N15Component } from './pages/n15/n15.component';
import { N16Component } from './pages/n16/n16.component';
import { N8Component } from './pages/n8/n8.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { OtrosComponent } from './pages/otros/otros.component';
import { PoliticasigComponent } from './pages/politicasig/politicasig.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { SellosSeguridadComponent } from './pages/sellos-seguridad/sellos-seguridad.component';
import { ServiComponent } from './pages/servi/servi.component';
import { TocadiscosComponent } from './pages/tocadiscos/tocadiscos.component';

const routes: Routes = [
  { path: 'home', component: InicioComponent},
  { path: 'admin', component: AdminComponent},
  { path: 'sellos', component: SellosSeguridadComponent},
  { path: 'productos', component: ProductosComponent},
  { path: 'producto/:id', component: ProductoComponent},
  { path: 'listasolicitudes', component: ListasolicitudesComponent},
  { path: 'solicitudes', component: SolicitudesComponent, canActivate: [AuthGuard]},
  { path: 'solcortinas', component: SolcortinasComponent, canActivate: [AuthGuard]},
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard]},
  { path: 'cotizaciones', component: CotizacionesComponent, canActivate: [AuthGuard]},
  { path: 'cotcortinas', component: CotcortinasComponent, canActivate: [AuthGuard]},
  { path: 'otros', component: OtrosComponent},
  { path: 'cortinas', component: CortinasComponent},
  { path: 'aeronautica', component: AeronauticaComponent},
  { path: 'nueva', component: NuevaCotizacionComponent},
  { path: 'n8', component: N8Component},
  { path: 'n15', component: N15Component},
  { path: 'n16', component: N16Component},
  { path: 'detalle/:id', component: DetalleCortinasComponent},
  { path: 'construccion', component: ConstruccionComponent},
  { path: 'escolar', component: EscolaresComponent},
  { path: 'tocadiscos', component: TocadiscosComponent},
  { path: 'desarrollo', component: DisenoProductosComponent},
  { path: 'compañia', component: EmpresaComponent},
  { path: 'certificaciones', component: CertificacionesComponent},
  { path: 'servicios', component: ServiComponent},
  { path: 'exportaciones', component: ExportacionesComponent},
  { path: 'noticias', component: NoticiasComponent},
  { path: 'politicas', component: PoliticasigComponent},
  { path: 'listasellos', component: SelloslistComponent, canActivate: [AuthGuard]},
  { path: '**', pathMatch: 'full', redirectTo: '/home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
