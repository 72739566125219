<div class="portada">
  <div class="contenido">
    <div class="container-fluid d-none d-lg-block d-xl-block">
      <br>
      <p class="titulo1">Moldeamos soluciones,</p>
      <p class="titulo2">inyectamos progreso.</p>
    </div>
    <div class="container-fluid d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
      <br>
      <h2 class="mvl">Moldeamos soluciones,</h2>
      <h3 class="mvl">&nbsp;&nbsp; <b>inyectamos progreso.</b></h3>
    </div>
    <div class="container-fluid trjtas">
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4">
          <div class="card h-100 servicios" [routerLink]="['/tocadiscos']">
            <img src="../../../assets/images/tocadiscos.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card h-100 servicios" [routerLink]="['/sellos']">
            <img src="../../../assets/images/seguridad.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card h-100 servicios" [routerLink]="['/cortinas']">
            <img src="../../../assets/images/persianas.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="max-width: 75%;">
      <div class="row">
        <div class="col-sm-7">
          <div class="card h-100 fnl" [routerLink]="['/desarrollo']">
            <div class="card-body">
              <img src="../../../assets/images/design.png"  width="100%" alt="">
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="card h-100 fnl" [routerLink]="['/otros']">
            <div class="card-body">
              <img src="../../../assets/images/mas-roductos.png"  width="100%" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
</div>


