export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBtxMrFHtvGOsMCyeh7X6X6BuMe0jurfNE",
    authDomain: "normarh-6e67f.firebaseapp.com",
    projectId: "normarh-6e67f",
    storageBucket: "normarh-6e67f.appspot.com",
    messagingSenderId: "566944516971",
    appId: "1:566944516971:web:148353e7bb56a646819a2e",
    measurementId: "G-2T6YETWN0R"
  }
};
