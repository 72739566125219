<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 6rem;">
  <h3><img src="../../../assets/images/request.png" width="60" alt=""> Listado de solicitudes:</h3>
  <hr>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Cliente</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Id</th>
          <th scope="col">Num</th>
          <th scope="col">Email</th>
          <th scope="col">Producto</th>
          <th scope="col">Cant.</th>
          <th scope="col">Color</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listado">
          <th>{{item.fecha | date}}</th>
          <td>{{item.empresa | titlecase}}</td>
          <td>{{item.telefono}}</td>
          <td>{{item.tipo}}</td>
          <td>{{item.numero}}</td>
          <td>{{item.email}}</td>
          <td>{{item.producto}}</td>
          <td>{{item.cantidad}}</td>
          <td>{{item.color}}</td>
          <td>
            <button class="btn btn-light" title="Ver detalle" (click)="detailRequest(item)" data-toggle="modal" data-target="#detailSolicitud"><i class="fas fa-th-list"></i></button>
            <button class="btn btn-light" title="Cotizar"><i class="fas fa-check"></i></button>
            <button class="btn btn-light" title="Rechazar"><i class="fas fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- Modal -->
<div *ngIf="detalleSolicitud" class="modal fade" id="detailSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detalle de la solicitud</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>Datos del cliente:</h4>
        <hr>
        <h5>Cliente: <b>{{detalleSolicitud.empresa | titlecase}}</b></h5>
        <h5>Solicitante: <b>{{detalleSolicitud.solicitante | titlecase}}</b></h5>
        <h5>Tipo de documento: <b>{{detalleSolicitud.documento}}</b></h5>
        <h5>Número documento: <b>{{detalleSolicitud.numero}}</b></h5>
        <h5>Teléfono: <b>{{detalleSolicitud.telefono}}</b></h5>
        <h5>Email: <b>{{detalleSolicitud.email}}</b></h5>
        <h5>Fecha de envío: <b>{{detalleSolicitud.fecha | date}}</b></h5>
        <h5>Pais: <b>{{detalleSolicitud.pais | titlecase}}</b></h5>
        <hr>
        <h4>Datos del producto:</h4>
        <hr>
        <h5>Artículo: <b>{{detalleSolicitud.producto}}</b></h5>
        <h5>Nombre producto: <b>{{detalleSolicitud.caracteristica}}</b></h5>
        <h5>Material: <b>{{detalleSolicitud.material}}</b></h5>
        <h5>Longitud de guaya: <b>{{detalleSolicitud.guaya}}</b></h5>
        <h5>Color: <b>{{detalleSolicitud.color}}</b></h5>
        <h5>Cantidad: <b>{{detalleSolicitud.cantidad}}</b></h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="cotizar(detalleSolicitud.id)" data-toggle="modal" data-target="#cotizarSolicitud">Generar Cotización</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div *ngIf="this.detalleSolicitud" class="modal fade" id="cotizarSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cotización:</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img *ngIf="this.detalleSolicitud.imagen" [src]="this.detalleSolicitud.imagen" alt="">
        </div>
        <form autocomplete="off" [formGroup]="cotizacionForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Longitud de la guaya:</label>
                <input type="text" class="form-control" placeholder="Longitud" formControlName="longitud" />
              </div>
              <div class="form-group">
                <label>Precio unitario:</label>
                <input type="number" class="form-control" placeholder="Precio x unidad" formControlName="precio" />
              </div>
              <div class="form-group">
                <label>Cantidad:</label>
                <input type="number" class="form-control" placeholder="Cantidad cotizada" formControlName="cantidad" />
              </div>
              <div class="form-group">
                <label>Numeración:</label>
                <input type="text" class="form-control" placeholder="Ingrese numeración" formControlName="numeracion" />
              </div>
              <div class="form-group">
                <label>IVA:</label>
                <input type="number" class="form-control" placeholder="Ingrese iva" formControlName="iva" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Despacho:</label>
                <input type="text" class="form-control"  formControlName="despacho" />
              </div>
              <div class="form-group">
                <label>Pago:</label>
                <input type="text" class="form-control"  formControlName="pago" />
              </div>
              <div class="form-group">
                <label>Garantía:</label>
                <input type="text" class="form-control"  formControlName="garantia" />
              </div>
              <div class="form-group">
                <label>Observaciones:</label>
                <textarea class="form-control" name="" id="" cols="30" rows="5" formControlName="observaciones"></textarea>
              </div>
            </div>
          </div> 
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="crearCotizacion(cotizacionForm.value)">Enviar Cotización</button>
      </div>
    </div>
  </div>
</div>
