<app-navbar></app-navbar>
<div class="container" style="margin-top: 6rem;">
  <img src="../../../assets/images/web/Header.png" width="100%" alt="">
  <br><br>
  <div class="normarh">
    <img src="../../../assets/images/web/fondo-normarh3-01.jpeg" width="100%" alt="">
  </div>
  <br><br>
  <div class="row">
    <div class="col-sm-6">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">MISIÓN</h5>
          <p>
            Somos una empresa manufacturera y comercializadora, orientada a: Diseñar, Fabricar y Reparar Moldes, y a  generar  productos mediante el proceso de inyección de Materiales Plásticos. Nuestros productos  están dirigidos a entidades prestadoras de Servicios  Públicos, Empresas de Diseño y Decoración, y otras del Sector Plástico. Efectuamos nuestra labor con ética hacia nuestros Clientes, Proveedores, Empleados  y Entidades del Estado, con respeto hacia el medio ambiente, con  Responsabilidad, Honestidad, Fiabilidad, Calidad e Innovación, generando utilidades a nuestros Accionistas y propiciando el bienestar de Nuestros Colaboradores.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">VISIÓN</h5>
          <p>
            A partir de los principios de calidad, oportunidad, seguridad y transparencia que caracterizan a nuestra organización, seremos los proveedores de soluciones más confiables del mercado, para satisfacción de nuestros clientes y bienestar de nuestros empleados y accionistas.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
