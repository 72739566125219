<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 6rem;">
  <h3><img src="../../../assets/images/cotizaciones.png" width="60" alt=""> Listado de cotizaciones cortinas:</h3>
  <hr>
  <br>
  <div class="col-4">
    <input type="text" class="form-control inputPadding" placeholder="Buscar cotización por cliente..." [(ngModel)]="textoBusqueda">
  </div>
  <br>
<br>
<table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Empresa</th>
        <th scope="col">Solicitante</th>
        <th scope="col">Fecha</th>
        <th scope="col">E-mail</th>
        <th scope="col">Documento</th>
        <th scope="col">Número</th>
        <th scope="col">Teléfono</th>
        <th scope="col">País</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of lista">
        <td>{{item.empresa}}</td>
        <td>{{item.solicitante}}</td>
        <td>{{item.fecha}}</td>
        <td>{{item.email}}</td>
        <td>{{item.documento}}</td>
        <td>{{item.numero}}</td>
        <td>{{item.telefono}}</td>
        <td>{{item.pais}}</td>
        <td>
            <button class="btn btn-primary" (click)="printCoti(item)" data-toggle="modal" data-target="#exampleModal"><i class="fas fa-print"></i></button>
            <button class="btn btn-success ml-1" (click)="whatsappcliente(item.telefono)"><i class="fab fa-whatsapp"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- Modal -->
<div *ngIf="cotSel" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Imprimir Cotización</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>     
      </div>
      <div class="modal-body">
        <div class="col-4">
          <label>Asignar precio flete:</label>
          <input type="number" class="form-control" placeholder="$ flete" (change)="valorFlete($event.target)">
          <br>
         </div>
       <div id="print-section">
        <img src="../../../assets/images/headerCorti.jpeg" width="100%" alt="">
        <div class="text-right">
         <p>FAP 688</p>
        </div>     
        <div class="cbz">         
         <table style="width: 100%;">
            <thead style="background-color: rgb(200, 200, 200);">
                <tr class="thr text-center">
                    <th><h4 class="mt-2"><b>COTIZACIÓN</b></h4></th>
                </tr>
            </thead>
        </table>
        </div>
       <div class="cbz">
        <div class="row">
          <div class="col-5">
            Fecha: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{cotSel.fecha | date: 'medium'}}
          </div>
          <div class="col-7 text-right">
            <b>Cliente: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{cotSel.solicitante | titlecase}}</b>
          </div>
        </div>
       </div>  
       <br>     
        <table class="tbcot" style="width: 100%;">
            <thead style="background-color: rgb(200, 200, 200);">
              <tr>
                <th scope="col">CÓDIGO DE PRODUCTO</th>
                <th scope="col">PRODUCTO</th>
                <th scope="col">CANTIDAD</th>
                <th scope="col">PRECIO UND</th>
                <th scope="col">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of cotSel.productos">
                <td>{{item.codigo}}</td>
                <td>{{item.producto}}</td>
                <td>{{item.cantidad}}</td>
                <td>${{item.preciounidad | number}}</td>
                <td>${{item.total | number}}</td>
              </tr>
            </tbody>
       </table>
       <hr>
       <table class="tbcot table-sm" style="width: 100%;">       
        <tbody>
          <tr>
            <td><h5>TOTAL</h5></td>
            <td><b>${{cotSel.totalcot | number}}</b></td>            
          </tr>
         <tr>
            <td>
              <p>FLETE(Compras inferiores a $350.000 antes de IVA se le adiciona el valor del flete)</p>
            </td>
            <td><b>${{flete | number}}</b></td>         
          </tr>
         <tr>
            <td><h5>IVA 19%</h5></td>
            <td><b>${{iva | number}}</b></td>    
          </tr>
          <tr>
            <td><h5>TOTAL + IVA + FLETE</h5></td>
            <td><b>${{liquidacion | number}}</b></td>    
          </tr>
        </tbody>
      </table>
      <hr>
      <br>
      <p>Atentamente;</p>
      <br><br>
      <img [src]="userData.firma" class="firma" width="120px" alt="">
      <p><b>{{userData.nombre | titlecase}}</b></p>
       </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" printSectionId="print-section" ngxPrint data-dismiss="modal">Imprimir</button>
      </div>
    </div>
  </div>
</div>