<app-nav-sec></app-nav-sec>
<br><br><br><br><br>
<div class="container-fluid">
    <h4>Listado de sellos:</h4>
    <hr>
    <table class="table">
        <thead>
          <tr>
            <th scope="col">Sello</th>
            <th scope="col">Nombre</th>
            <th scope="col">Categoria</th>
            <th scope="col">Guaya</th>
            <th scope="col">Material</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of sellos">
            <th><img [src]="item.imagen" width="100" alt=""></th>
            <td>{{item.producto}} - {{item.nombre}}</td>
            <td>{{item.categoria | titlecase}}</td>
            <td>{{item.tipo}}</td>
            <td>{{item.material}}</td>
            <td>
                <button class="btn btn-primary btn-sm" (click)="selloseleccionado(item)" data-toggle="modal" data-target="#exampleModal"><i class="fas fa-edit"></i></button>
                <button class="btn btn-danger ml-1 btn-sm"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
</div>
    <div *ngIf="sellosel" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar los datos del sello:</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <h5>Imagen del producto en catálogo:</h5>
                <img [src]="sellosel.imagen" width="250" alt="">
                <br>
                <input type="file">
                <hr>
                <label>Nombre</label>
                <input type="text" class="form-control" [(ngModel)]="nombre">
                <label>Tipo</label>
                <input type="text" class="form-control" [(ngModel)]="tipo">
                <label>Categoría</label>
                <input type="text" class="form-control" [(ngModel)]="categoria">
                <label>Orden</label>
                <input type="text" class="form-control" [(ngModel)]="orden">
                <label>Descripción</label>
                <textarea class="form-control" cols="30" rows="5" [(ngModel)]="descripcion">{{sellosel.descripcion}}</textarea>
                <label>Subcategorías</label>
                <table class="table table-sm table-striped table-hover">                   
                    <tbody>
                      <tr *ngFor="let subcate of sellosel.subcategoria">
                        <td><small>- {{subcate | titlecase}}</small></td>
                        <td><button class="btn btn-ligth btn-sm"><i class="fas fa-ban"></i></button></td>                      
                      </tr>                    
                    </tbody>
                  </table>

            </div>
            <div class="col-6">
                <h5>Imagen del producto en detalle:</h5>
                <img [src]="sellosel.imagenDetalle" width="250" alt="">
                <br>
                <input type="file" class="mt-3">
                <hr>
                <label>Material</label>
                <input type="text" class="form-control" [(ngModel)]="material">
                <label>Guaya</label>
                <input type="text" class="form-control" [(ngModel)]="guaya">
                <label>Aplicación</label>
                <input type="text" class="form-control" [(ngModel)]="aplicacion">
                <label>Dimensiones</label>
                <input type="text" class="form-control" [(ngModel)]="dimensiones">
                <label>Disponibilidad</label>
                <input type="text" class="form-control" [(ngModel)]="disponibilidad">
                <label>Marcación</label>
                <input type="text" class="form-control" [(ngModel)]="marcacion">
                <label>Agregar subcategoría</label>
                <select class="form-control">
                    <option selected value="">Seleccione la subcategoría...</option>
                    <option value="energia">energía</option>
                    <option value="gas">gas</option>
                    <option value="acueducto">acueducto</option>
                    <option value="transporte">transporte</option>
                    <option value="agricola">agrícola</option>
                    <option value="farmaceutico">farmacéutico</option>
                    <option value="informatico">informático</option>
                    <option value="ancla">ancla</option>
                    <option value="rotor">rotor</option>
                    <option value="candado">candado</option>
                    <option value="botella">botella</option>
                    <option value="tornillo">tornillo</option>
                    <option value="inserto">inserto metálico</option>
                    <option value="correa">correa</option>
                    <option value="chapa">chapa</option>
                    <option value="medidores">medidores</option>
                    <option value="contenedores">contenedores</option>
                    <option value="taximetros">taxímetros</option>
                    <option value="servidores">servidores</option>
                    <option value="tragamonedas">tragamonedas</option>
                    <option value="cajas">cajas</option>
                    <option value="dispensadores">dispensadores</option>
                    <option value="monederos">monederos</option>
                </select>
            </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary">Actualizar Sello</button>
        </div>
      </div>
    </div>
  </div>
