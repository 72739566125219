<app-navbar></app-navbar>
<div class="container-fluid" style="margin-top: 6rem;width: 90%;">
  <div>
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>DISEÑO Y DESARROLLO DE NUEVOS PRODUCTOS</h3>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/serv1.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <p class="card-text" style="text-align: justify;"><b>NORMARH S.A.S.</b> contamos con un departamento dedicado al diseño de producto, donde damos forma a la solución de sus necesidades, nuestro compromiso con la innovación es constante, razón por la cual buscamos que nuestros productos generen el mayor impacto en el mercado. .</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div>
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>PROCESO DE INGENIERÍA INVERSA</h3>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <p style="text-align: justify;">
              Contamos con tecnología avanzada que nos permite reducir procesos en cuanto al desarrollo de trabajos, convirtiéndonos en una empresa que optimiza el tiempo durante un proyecto y da prioridad a las necesidades de nuestros clientes. En la actualidad hacemos uso de tecnologías como, escáner 3D para lograr la mayor precisión durante nuestros procesos de producción, y prototipadoras 3D para hacer pruebas de usabilidad de nuestros productos y entregarle al cliente un producto con el cual satisfaga sus necesidades
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/servi2.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/servi3.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div>
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>INYECCIÓN DE PLÁSTICOS</h3>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/servi4.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <p class="card-text" style="text-align: justify;">Como servicio adicional a una de nuestras principales actividades de producción, en NORMARH S.A.S. ofrecemos servicios de inyección de plásticos a empresas externas quienes busquen tercerizar sus procesos productivos. Actualmente inyectamos diferentes tipos de plásticos como los son: Polipropileno, policarbonato, acetal, nylon y entre otros, de acuerdo a la necesidad del cliente.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div>
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>DISEÑO Y FABRICACIÓN DE MOLDES</h3>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <p class="card-text" style="text-align: justify;">Dentro de nuestras unidades estratégicas de negocio (U.E.N) ofrecemos el servicio de diseño y desarrollo de moldes para inyección, gracias a que contamos con personal capacitado para dichas labores nos hemos caracterizado por lograr moldes de alta calidad, que cumplen con los estándares requeridos por los clientes.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/servi5.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div>
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>MECANIZADO CNC</h3>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <p class="card-text" style="text-align: justify;">Como parte de la diversificación y la adaptación de nuevas tecnologías en el campo industrial, en NORMARH S.A.S ofrecemos servicios de mecanizado por control numérico, para que las piezas de precisión de nuestros clientes queden realizadas de la mejor manera, esto, haciendo uso de tornos y fresadoras, en combinación con la experiencia de profesionales en el campo de la programación y manejo de estas herramientas.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <img src="../../../assets/images/web/servicios/servi6.jpeg" width="100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
