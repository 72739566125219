import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UsercontrolService } from 'src/app/services/usercontrol.service';


@Component({
  selector: 'app-nav-sec',
  templateUrl: './nav-sec.component.html',
  styleUrls: ['./nav-sec.component.scss']
})
export class NavSecComponent implements OnInit {

  uploadPercent1: Observable<number>;
  uploadPercent2: Observable<number>;
  urlImage1: string;
  urlIfirma: string;
  imagen: any;
  firma: any;
  pass: any;
  pass1: any;
  pass2: any;
  passactivo = false;

  usuario = {
    nombre:'',
    imagen:'',
    email:'',
    firma:'',
    id:'',
    pass:''
  }

  constructor(public userSvc: UsercontrolService,
              private storage: AngularFireStorage) { }

  ngOnInit(): void {
    //console.log(this.userSvc.usuarioLog);
    
    this.imagen = this.userSvc.usuarioLog.imagen;
    this.firma = this.userSvc.usuarioLog.firma;
  }

  handleImage1(event){
    const idImg = Math.random().toString(36).substring(2);
    const file = event.target.files[0];
    const filePath = `users/prds_${idImg}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent1 = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => {
      ref.getDownloadURL().subscribe( urlImg => {
        this.urlImage1 = urlImg;
        this.imagen = urlImg;
      });
    })).subscribe();
  }

  handleImage2(event){
    const idImg = Math.random().toString(36).substring(2);
    const file = event.target.files[0];
    const filePath = `users/prds_${idImg}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent2 = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => {
      ref.getDownloadURL().subscribe( urlImg => {
        this.urlIfirma = urlImg;
        this.firma = urlImg;
      });
    })).subscribe();
  }


  passActiv(){
    this.passactivo = true;
  }

  actualizarUsuario(){
    console.log(this.usuario);
    if (this.imagen === undefined) {
      this.imagen = '';
    }
    
    if (!this.passactivo) {
      this.usuario.nombre = this.userSvc.usuarioLog.nombre;
      this.usuario.email =this.userSvc.usuarioLog.email;
      this.usuario.id = this.userSvc.usuarioLog.id;
      this.usuario.imagen = this.imagen;
      this.usuario.firma = this.firma;
      this.usuario.pass = this.userSvc.usuarioLog.pass;
      console.log(this.usuario);  
      this.userSvc.actualizarPerfil(this.usuario.id, this.usuario).then(() => {
        localStorage.setItem('dataUserNormarh', JSON.stringify(this.usuario));
        Swal.fire('Usuario actualziado correctamente');
        window.location.reload();
      })    
    } else {
      if (this.pass1 && this.pass2) {
        if (this.pass1 === this.pass2) {
          this.usuario.nombre = this.userSvc.usuarioLog.nombre;
          this.usuario.email =this.userSvc.usuarioLog.email;
          this.usuario.id = this.userSvc.usuarioLog.id;
          this.usuario.imagen = this.imagen;
          this.usuario.firma = this.firma;
          this.usuario.pass = this.pass1;
          console.log(this.usuario); 
          this.userSvc.actualizarPerfil(this.usuario.id, this.usuario).then(() => {
            localStorage.setItem('dataUserNormarh', JSON.stringify(this.usuario));
            Swal.fire('Usuario actualziado correctamente');
            window.location.reload();
          })             
        }else{
          Swal.fire('Verifique que las contraseñas coincidan');
        }
      }else {
        Swal.fire('Debe ingresar los contraseñas para actualizar');
      }
    }    
  }

  cerrarSesion(){
    localStorage.removeItem('dataUserNormarh');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

}
