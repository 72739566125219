import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsercontrolService {
  usuarioLog: any;

  constructor(private db: AngularFirestore) {
    this.loadStorage();
   }

  loadStorage(){
    if (localStorage.getItem('dataUserNormarh')) {
      this.usuarioLog = JSON.parse( localStorage.getItem('dataUserNormarh')!);
    }
  }

  actualizarPerfil(idUsuario: any, user: any){
    return this.db.collection('usuarios').doc(idUsuario).update(user);
  }
}
