import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductosService } from 'src/app/services/productos.service';
import { UsercontrolService } from 'src/app/services/usercontrol.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent implements OnInit {

  listado: any;
  detalleSolicitud: any;
  public formSubmitted = false;

  public cotizacionForm = this.fb.group({
    solicitud: ['', Validators.required],
    articulo: ['', Validators.required],
    color: ['', Validators.required],
    caracteristica: ['', Validators.required],
    material: ['', Validators.required],
    marcacion: ['', Validators.required],
    guaya: ['', Validators.required],
    longitud: ['', Validators.required],
    cantidad: ['', Validators.required],
    precio: ['', Validators.required],
    numeracion: ['', Validators.required],
    despacho: ['', Validators.required],
    pago: ['', Validators.required],
    garantia: ['', Validators.required],
    observaciones: ['', Validators.required],
    iva: ['', Validators.required],
    total: ['', Validators.required],
    fecha: new Date().getTime()
  });

  prodSolicitud: any;
  constructor(private prodSvc: ProductosService,
              private fb: FormBuilder,
              private usrcontrolSvc: UsercontrolService) { }

  ngOnInit(): void {
    this.cargarSolicitudes();
  }

  campoNoValido(campo: string): boolean {
    if (this.cotizacionForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  cargarSolicitudes(){
    this.prodSvc.loadSolicitudes()
                .subscribe(res => {
                  this.listado = res;
                })
  }

  detailRequest(solicitud: any){
    console.log(solicitud);
    this.prodSvc.cargarProductoPorNombre(solicitud.producto)
                .subscribe(res => {
                  this.prodSolicitud = res;
                  this.detalleSolicitud = solicitud;
                  this.detalleSolicitud.ficha = this.prodSolicitud[0].fichaimg;
                  this.detalleSolicitud.imagen = this.prodSolicitud[0].imagen;
                  console.log(this.detalleSolicitud);
                })
    
    
  }

  cotizar(idSolicitud: string){
    console.log(idSolicitud);
  }

  crearCotizacion(cotizacionData: any){
    cotizacionData.solicitud = this.detalleSolicitud.id;
    cotizacionData.empresa = this.detalleSolicitud.empresa;
    cotizacionData.solicitante = this.detalleSolicitud.solicitante;
    cotizacionData.pais = this.detalleSolicitud.pais;
    cotizacionData.articulo = this.detalleSolicitud.producto;
    cotizacionData.caracteristica = this.detalleSolicitud.caracteristica;
    cotizacionData.color = this.detalleSolicitud.color;
    cotizacionData.material = this.detalleSolicitud.material;
    cotizacionData.marcacion = this.detalleSolicitud.marcacion;
    cotizacionData.guaya = this.detalleSolicitud.guaya;
    cotizacionData.cantidad = this.detalleSolicitud.cantidad;
    cotizacionData.iva = Number(((cotizacionData.precio * cotizacionData.cantidad) * cotizacionData.iva)/100);
    cotizacionData.total = Number((cotizacionData.precio * cotizacionData.cantidad) + cotizacionData.iva);
    cotizacionData.imagen = this.detalleSolicitud.imagen;
    cotizacionData.ficha = this.detalleSolicitud.ficha;
    console.log(cotizacionData);
    this.prodSvc.crearCotizacion(cotizacionData).then(() => {
      Swal.fire('cotizacion enviada correctamente');
    }); 
  }

}
