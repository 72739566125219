<!-- Just an image -->
<div class="fter">
  <div class="card-deck">
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <img src="../../../assets/images/logoBlanco.png" alt="">
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <br>
          <h2 style="text-decoration-line: overline;">Encuéntranos</h2>
          <a href="https://www.facebook.com/normarhsas" target="_blank"><img src="../../../assets/images/facebook.svg" class="img-fluid mr-4" alt="" width="21"></a>
          <a href="https://www.instagram.com/normarh.sas/?hl=es-la" target="_blank"> <img src="../../../assets/images/instagram.svg" class="img-fluid mr-4" alt="" width="40"></a>
          <a href="https://www.linkedin.com/company/normarh-s-a-s?original_referer=https%3A%2F%2Flinktr.ee%2F" target="_blank"><img src="../../../assets/images/linkedin.svg" class="img-fluid" alt="" width="40"></a>
        </div>
      </div>
    </div>
  </div>
</div>
