<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 6rem;">
  <h3><img src="../../../assets/images/target.png" width="60" alt=""> Listado de clientes:
    <button *ngIf="tarjeta" class="btn btn-ligth" (click)="cambioVisual('tabla')"><i class="fas fa-caret-down modo"></i></button>
    <button *ngIf="!tarjeta" class="btn btn-ligth" (click)="cambioVisual('tarjeta')"><i  class="fas fa-caret-up modo"></i></button>
    &nbsp;&nbsp;&nbsp;<button class="btn btn-success" data-toggle="modal" data-target="#nuevoCliente">Crear Cliente</button>
  </h3>
  <div class="col-4">
    <input type="text" class="form-control inputPadding" placeholder="Buscar cliente..." [(ngModel)]="textoBusqueda">
  </div>
  <hr>
 <div *ngIf="tarjeta">
  <div class="row row-cols-1 row-cols-md-3">
    <div class="col mb-4" *ngFor="let item of clientes | filter: textoBusqueda: 'nombre'">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title"><i class="far fa-user"></i>&nbsp; - {{item.empresa | titlecase}}</h5>
          <h5 class="card-title"><i class="fas fa-id-card"></i>&nbsp; - {{item.documento}}</h5>
          <h5 class="card-title"><i class="fas fa-id-card-alt"></i>&nbsp; - {{item.numero}}</h5>
          <h5 class="card-title"><i class="fas fa-envelope-open-text"></i>&nbsp; - {{item.email}}</h5>
          <h5 class="card-title"><i class="fas fa-mobile-alt"></i>&nbsp; - {{item.telefono}}</h5>
          <div class="text-right">
            <button class="btn btn-success" (click)="nuevaCotizacion(item)" >Cotizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
 <div *ngIf="!tarjeta">
  <div class="responsive-table">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Documento</th>
          <th scope="col">Número</th>
          <th scope="col">E-mail</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of clientes | filter: textoBusqueda: 'nombre'">
          <th>{{item.empresa | titlecase}}</th>
          <td>{{item.documento | titlecase}}</td>
          <td>{{item.numero}}</td>
          <td>{{item.email}}</td>
          <td>{{item.telefono}}</td>
          <td>
            <button class="btn btn-success" (click)="nuevaCotizacion(item)" >Cotizar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
 </div>
</div>
<!-- Modal -->
<div class="modal fade" id="nuevoCliente" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Registrar Cliente</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label>Nombre Completo:</label>
        <input type="text" class="form-control" placeholder="Nombre del cliente">

        <label>Documento:</label>
        <select class="form-control">
          <option selected value="">Seleccione tipo de documento...</option>
          <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
          <option value="Cédula de extranjería">Cédula de extranjería</option>
          <option value="Pasaporte">Pasaporte</option>
          <option value="Otro">Otro</option>
        </select>

        <label>Número de documento:</label>
        <input type="text" class="form-control" placeholder="uúmero">

        <label>Email:</label>
        <input type="text" class="form-control" placeholder="Correo electrónico">

        <label>Teléfono</label>
        <input type="text" class="form-control" placeholder="Teléfono/celular">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Crear Cliente</button>
      </div>
    </div>
  </div>
</div>


