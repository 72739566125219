<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <a class="navbar-brand" href="#">
    <img src="../../../assets/images/Logo.svg" width="180" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" [routerLink]="['/solicitudes']" >SOLICITUDES SELLOS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/clientes']">CLIENTES</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/cotizaciones']">COTIZACIONES SELLOS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/listasellos']">GESTION DE SELLOS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/solcortinas']">SOLICITUDES DE CORTINAS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/cotcortinas']">COTIZACIONES CORTINAS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" (click)="cerrarSesion()"><i class="fas fa-sign-out-alt"></i> SALIR</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link"> <small><b>{{userSvc.usuarioLog.nombre | titlecase}}</b></small></a>
      </li>
      <li>
        <button *ngIf="!userSvc.usuarioLog.imagen" class="btn btn-light" data-toggle="modal" data-target="#perfilData"><img src="../../../assets/images/user.png" alt=""></button>
        <button *ngIf="userSvc.usuarioLog.imagen" class="btn btn-light" data-toggle="modal" data-target="#perfilData"><img [src]="userSvc.usuarioLog.imagen" class="rounded" width="35" alt=""></button>
      </li>
    </ul>  
  </div>
</nav>

<!-- Modal -->
<div class="modal fade" id="perfilData" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Mis datos:</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <br>
          <img *ngIf="!imagen" src="../../../assets/images/userBig.png" width="150" alt="">
          <img *ngIf="imagen" class="rounded" [src]="imagen" width="150" alt="">
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent1 | async) + '%'"></div>
        </div>
          <br>
          <label>Cambiar imagen de perfil</label>
          <input type="file" (change)="handleImage1($event)">
        </div>
        <br>
        <div>
          <h4>Nombre: <b>{{userSvc.usuarioLog.nombre | titlecase}}</b></h4>
          <h4>E-mail: <b>{{userSvc.usuarioLog.email}}</b></h4>
          <hr>
          <label>Mi firma registrada: </label>
          <br>
          <img [src]="firma" width="150" alt="">
          <br>
          <label>Modificar firma:</label>
          <br>
          <input type="file" (change)="handleImage2($event)">
          <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent2 | async) + '%'"></div>
          </div>
          <br>
          <button class="btn btn-success mt-2" (click)="passActiv()">Cambiar mi contraseña:</button>
          <br>
         <div *ngIf="passactivo">
          <br>
          <label>Ingrese su contraseña actual:</label>
          <input type="password" class="form-control" placeholder="Password" [(ngModel)]="pass">
          <div  class="row">
            <div class="col-6">
              <label>Nuevo password:</label>
              <input type="password" class="form-control" placeholder="Password" [(ngModel)]="pass1">
            </div>
            <div class="col-6">
              <label>Confirme password:</label>
              <input type="password" class="form-control" placeholder="Password" [(ngModel)]="pass2">
            </div>
          </div>
         </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="actualizarUsuario()">Actualizar Perfil</button>       
      </div>
    </div>
  </div>
</div>

