<app-navbar></app-navbar>
<div class="container-fluid" style="margin-top: 8rem;margin-bottom: 4rem;min-height: 600px;">
  <h3 style="color: rgb(80, 80, 80);">Lista de productos para solicitar cotización</h3>
  <hr>
  <br>
  <div class="row">
    <div class="col-sm-8">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">         
           <div>
            <h5>Solicitud de cortinas:</h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Producto</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Color</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listaCortinas; let i = index">
                  <td>{{item.producto | titlecase}}</td>
                  <td>{{item.cantidad}}</td>
                  <td>{{item.color}}</td>
                  <td><button class="btn btn-danger" (click)="borrarCortina(i)"><i class="fas fa-trash"></i></button></td>
                </tr>
              </tbody>
            </table>
           </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <form autocomplete="off" [formGroup]="solicitudForm">
            <div class="form-group">
              <label>Empresa</label>
              <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa" />
            </div>
            <div class="form-group">
              <label>Nombre Solicitante:</label>
              <input type="text" class="form-control" placeholder="Nombre y apellidos" formControlName="solicitante" />
            </div>
            <div class="form-group">
              <label class="control-label">Tipo de documento:</label>
              <select class="form-control custom-select" formControlName="documento">
                <option selected value="">Seleccione la su tipo de documento:</option>
                <option value="Cédula ciudadanía">Cédula ciudadanía</option>
                <option value="Cédula extranjería">Cédula extranjería</option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="NIT">NIT</option>
              </select>
            </div>
            <div class="form-group">
              <label>Número identificación</label>
              <input type="number" class="form-control" placeholder="Número documento" formControlName="numero" />
            </div>
            <div class="form-group">
              <label>E-mail</label>
              <input type="email" class="form-control" placeholder="Correo electrónico" formControlName="email" />
            </div>
            <div class="form-group">
              <label>Teléfono</label>
              <input type="number" class="form-control" placeholder="Número telefónico" formControlName="telefono" />
            </div>
            <div class="form-group">
              <label>Pais</label>
              <input type="text" class="form-control" placeholder="Pais de envío" formControlName="pais" />
            </div>
            <button (click)="enviarSolicitudCortinas(solicitudForm.value)" style="background-color: #f68d1d;color: #fff;" class="btn btn-block">Enviar solicitud de cotización</button>
            <button style="background-color: #092e54;color: #fff;" class="btn btn-block">Seguir Comprando</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
