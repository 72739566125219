<nav id="menu" class="navbar navbar-expand-lg navbar-light bg-light fixed-top" #stickyMenu [class.sticky]="sticky">
  <a class="navbar-brand" href="#">
   <img src="../../../assets/images/Logo.png" width="180" alt="">
 </a>
 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>

 <div class="collapse navbar-collapse" id="navbarSupportedContent">
   <ul class="navbar-nav mr-auto">
     <li class="nav-item active">
       <a class="nav-link" href="#">INICIO</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SOMOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SERVICIOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">EXPORTACIONES</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">NOTICIAS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">HABEAS DATA</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">POLÍTICA SIG</a>
     </li>
   </ul>
 </div>
</nav>

<div class="portada">
 <div class="contenido">
   <div class="text-center">
     <br><br><br><br><br>
     <h1 style="color: black;">- AERONÁUTICA -</h1>
   </div>
 </div>
</div>
<div class="container-fluid">
 <img src="../../../assets/images/aeronautica/perillas.png" width="600" class="img-fluid" alt="">
</div>
<br>
<div class="container-fluid" style="max-width: 90%;">

  <div class="publicaciones">
      <div class="container-fluid">
          <div class="col-md-12"><br>
              <!--Carousel Wrapper-->
              <div id="multi-item-example1" class="carousel slide carousel-multi-item" data-ride="carousel">
                  <!--Controls-->
                  <div class="controls-top">
                      <a class="btn-floating float-left" href="#multi-item-example1" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                      <a class="btn-floating float-right" href="#multi-item-example1" data-slide="next"><i class="fa fa-chevron-right"></i></a>
                  </div>
                  <!--/.Controls-->
                  <!--Slides-->
                  <div class="carousel-inner" role="listbox">

                      <!--First slide-->
                      <div class="carousel-item active">

                          <div class="col-md-3" style="float:left">
                              <div class="card mb-2">
                                  <div class="card-body">
                                    <img src="../../../assets/images/aeronautica/85.png" class="img-fluid" alt="">
                                  </div>
                              </div>
                          </div>

                          <div class="col-md-3" style="float:left">
                              <div class="card mb-2">
                                  <div class="card-body">
                                    <img src="../../../assets/images/aeronautica/103.png" class="img-fluid" alt="">
                                  </div>
                              </div>
                          </div>

                          <div class="col-md-3" style="float:left">
                              <div class="card mb-2">
                                  <div class="card-body">
                                    <img src="../../../assets/images/aeronautica/11.png" class="img-fluid" alt="">
                                  </div>
                              </div>
                          </div>

                          <div class="col-md-3" style="float:left">
                              <div class="card mb-2">
                                  <div class="card-body">
                                    <img src="../../../assets/images/aeronautica/152.png" class="img-fluid" alt="">
                                  </div>
                              </div>
                          </div>

                      </div>
                      <!--/.First slide-->

                      <!--Second slide-->
                      <div class="carousel-item">

                        <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/aeronautica/154.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>
                    </div>

                      </div>
                      <!--/.Second slide-->
                  </div>
                  <!--/.Slides-->
              </div>
              <!--/.Carousel Wrapper-->
          </div>
      </div>
      <br>
  </div>

  <br>

  <img src="../../../assets/images/aeronautica/servicioAero.png" width="100%" alt="">
<br><br>
<div class="fter">
  <div class="card-deck">
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <img src="../../../assets/images/logoBlanco.png" alt="">
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <br>
          <h2 style="text-decoration-line: overline;">Encuéntranos</h2>
          <img src="../../../assets/images/facebook.svg" class="img-fluid mr-4" alt="" width="21">
          <img src="../../../assets/images/instagram.svg" class="img-fluid mr-4" alt="" width="40">
          <img src="../../../assets/images/linkedin.svg" class="img-fluid" alt="" width="40">
        </div>
      </div>
    </div>
  </div>
</div>
