<app-navbar></app-navbar>
<div class="box" style="margin-top: 6rem;">
  <div class="container">
    <div class="card">
      <div class="card-body titulo">
        <div class="text-center ">
          <h3>POLÍTICA DEL SISTEMA INTEGRADO DE GESTIÓN</h3>
        </div>
      </div>
    </div>
    <br>
    <p>
      Satisfacemos las necesidades y expectativas de nuestros clientes por medio de soluciones integrales en metalmecánica e inyección de plásticos, soportadas en altos estándares de calidad, innovación y mejoramiento continuo, actuando en el marco de los requisitos legales aplicables, en búsqueda de la competitividad para asegurar el crecimiento de la compañía.
    </p>
    <p>
      Implementamos, mantenemos y mejoramos un sistema de gestión orientado a mantener la integridad de los procesos, a la prevención de actividades ilegales, actos terroristas, corrupción y soborno al interior de la Organización y/o a través de la cadena logística del comercio internacional.
    </p>
    <p>
      Desarrollamos las acciones necesarias para proteger y asegurar la salud física y mental de los trabajadores.
    </p>
    <br>
    <h3>
      OBJETIVOS SISTEMA INTEGRADO DE GESTION:
    </h3>
    <br>
    <ul>
      <li><b>1. Satisfacer los requisitos de los clientes en términos de calidad, oportunidad, precio y servicio.</b></li>
      <li><b>2. Prevenir la ocurrencia de acciones ilegales o actos terroristas en nuestros procesos internos y de comercio exterior.</b></li>
      <li><b>3. Buscar el compromiso de los clientes, proveedores y demás asociados de negocio con la seguridad de nuestras operaciones.</b></li>
      <li><b>4. Mejorar continuamente el Sistema Integrado de Gestión a través de la medición de los procesos, de la gestión de los riesgos identificados y de la implementación de acciones correctivas y de mejora.</b></li>
      <li><b>5. Proteger la seguridad y salud de nuestros trabajadores, mediante la implementación y mejora continua del Sistema de Gestión de la Seguridad y Salud en el Trabajo SGSST.</b></li>
      <li><b>6. Implantar la cultura de seguridad y salud en el trabajo con el compromiso y la participación de todos los trabajadores y con el apoyo de los entes asociados.</b></li>
      <li><b>7. Asegurar el cumplimiento de los requisitos reglamentarios y legales aplicables.</b></li>
    </ul>
    <br>
    <div class="text-center">
      <img src="../../../assets/images/noticias/Logo-Normarh.png" width="80" alt="">&nbsp;<img src="../../../assets/images/noticias/Calidad_BV_2015.png" width="450" alt="">
    </div>
  </div>
  <br>
</div>
<app-footer></app-footer>
