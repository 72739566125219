import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  pass: any;
  passin: any;
  usuarios: any;
  mail: any;
  usuario: any;
  constructor(private prodSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {
    this.prodSvc.cargarusuarios()
                .subscribe(res => {
                  this.usuarios = res;
                })
  }

  enlace(){
    const facha = new Date();
    window.alert('DNS zones update in progress. Activation done ' + facha)
  }

  userSelect(user: any){
   this.pass = user.target.value;    
  }

  ingresar(){
    if (this.pass === this.passin && this.mail) {
      this.prodSvc.cargarUsuarioPorEmail(this.mail)
                  .subscribe(res => {
                    this.usuario = res[0];
                    localStorage.setItem('dataUserNormarh', JSON.stringify(this.usuario));
                    this.router.navigate(['/solicitudes']);
                  });     
    } else {
      Swal.fire('Por favor verifique sus datos de ingreso');
    }
  }

}
