import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-n15',
  templateUrl: './n15.component.html',
  styleUrls: ['./n15.component.scss']
})
export class N15Component implements OnInit {

  @ViewChild('stickyMenu') menuElement: ElementRef;
  sticky = false;
  elementPosition: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(){
      const windowScroll = window.pageYOffset;
      if (windowScroll > this.elementPosition){
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }

}
