import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {

  productDetail: any;
  public formSubmitted = false;
  client: any;
  cliente = {
    empresa: '',
    solicitante: '',
    documento: '',
    numero: '',
    email: '',
    telefono: '',
    pais: ''
  }

  public solicitudForm = this.fb.group({
    tipo: ['Sellos', Validators.required],
    cantidad: ['', Validators.required],
    color: ['', Validators.required],
    producto: [''],
    caracteristica: [''],
    material: [''],
    marcacion: [''],
    guaya: [''],
    longitud: ['', Validators.required],
    observaciones: [''],
    fecha: new Date().getTime(),
    empresa: ['', Validators.required],
    solicitante: ['', Validators.required],
    documento: ['', Validators.required],
    numero: ['', Validators.required],
    telefono: ['', Validators.required],
    email: ['', Validators.required],
    pais: ['', Validators.required],
  });


  listaSellos: any = [];
  idProd: any;


  constructor(private prodSvc: ProductosService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadStorage();
    this.idProd = this.route.snapshot.paramMap.get('id');
    this.prodSvc.cargarproductobyid(this.idProd)
                .subscribe(res => {
                  this.productDetail = res;                                
                });
  }

  loadStorage(){
    if (localStorage.getItem('dataClienteCotizacionNormarSellos')) {
      this.client = JSON.parse( localStorage.getItem('dataClienteCotizacionNormarSellos'));

      this.solicitudForm = this.fb.group({
        tipo: ['Sellos', Validators.required],
        cantidad: ['', Validators.required],
        color: ['', Validators.required],
        producto: [''],
        caracteristica: [''],
        material: [''],
        marcacion: [''],
        guaya: [''],
        longitud: ['', Validators.required],
        observaciones: [''],
        fecha: new Date().getTime(),
        empresa: [this.client.nombre, Validators.required],
        solicitante: [this.client.solicitante, Validators.required],
        documento: [this.client.documento, Validators.required],
        numero: [this.client.numero, Validators.required],
        telefono: [this.client.telefono, Validators.required],
        email: [this.client.email, Validators.required],
        pais: [this.client.pais, Validators.required],
      });
    }
  }

  campoNoValido(campo: string): boolean {
    if (this.solicitudForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  enviarSolicitud(solicitud: any){
    this.listaSellos = [];
    this.cliente.empresa = solicitud.empresa;
    this.cliente.solicitante = solicitud.solicitante;
    this.cliente.documento = solicitud.documento;
    this.cliente.numero = solicitud.numero;
    this.cliente.email = solicitud.email;
    this.cliente.telefono = solicitud.telefono;
    this.cliente.pais = solicitud.pais;
    solicitud.producto = this.productDetail.producto;
    solicitud.caracteristica = this.productDetail.nombre;
    solicitud.material = this.productDetail.material;
    solicitud.marcacion = this.productDetail.marcacion;
    solicitud.guaya = this.productDetail.tipo;
    solicitud.estado = 'Enviado';
    console.log(this.cliente);
    console.log(solicitud);
    
     localStorage.setItem('dataClienteCotizacionNormarSellos', JSON.stringify(this.cliente));
    this.prodSvc.verificaCliente(solicitud.numero)
                .subscribe(res => {
                   console.log(res.length);
                  if (res.length === 0) {
                    this.prodSvc.crearCliente(this.cliente);
                  }
                });
    this.prodSvc.envioSolicitudCotizacion(solicitud).then(() => {
      Swal.fire('Solicitud enviada correctamente, nos comunicaremos con usted para el envío de la cotización')
    }); 
  }
}
