<nav id="menu" class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" #stickyMenu [class.sticky]="sticky">
  <a class="navbar-brand" href="#">
   <img src="../../../assets/images/Logo-blanco.png" width="180" alt="">
 </a>
 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>

 <div class="collapse navbar-collapse" id="navbarSupportedContent">
   <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link" href="#">INICIO</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">SOMOS</a>
    </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SERVICIOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">EXPORTACIONES</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">NOTICIAS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">HABEAS DATA</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">POLÍTICA SIG</a>
     </li>
   </ul>
 </div>
</nav>

<div class="portada">
 <div class="contenido">
   <div class="text-center">
     <br><br><br><br><br><br>
     <h1>- JUEGO ENROLLABLE N16 -</h1>
   </div>
   <div class="text-center">
     <button  style="border-color: #fff !important; border-radius: 15px; color: #fff !important; font-size: 25px; width: 350px;height: 80px;" class="btn btn-outline-primary mb-4"><a href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/catalogos%2FN16.pdf?alt=media&token=e3a59049-4a17-4e84-8b7e-3521a7b7f118" target="_blank"><b>VER</b> CATÁLOGO <i class="fas fa-long-arrow-alt-down"></i></a></button>
   </div>
 </div>
</div>
<div class="container">
 <div class="text-center">
   <br><br><br>
   <img src="../../../assets/images/N16/N16.png" class="img-fluid" width="70%" alt="">
   <br><br><br>
   <button class="btn btn-outline-primary btn8"><a href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/catalogos%2FN16.pdf?alt=media&token=e3a59049-4a17-4e84-8b7e-3521a7b7f118" target="_blank">CATÁLOGO <b>PDF</b> <i class="fas fa-long-arrow-alt-down"></i></a></button>
   <br>
 </div>
</div>
<br>
<app-footer></app-footer>
