<app-navbar></app-navbar>
<div class="container-fluid text-center">
  <br><br><br><br><br>
  <h1>- {{nombre | uppercase}} -</h1>
</div>
<div>
  <img [src]="imagen" class="img-fluid" width="100%" alt="">
</div>
<div class="container-fluid" style="max-width: 97%;">
  <div class="d-none d-lg-block d-xl-block">
    <div class="row row-cols-1 row-cols-md-4">
      <div class="col mb-4" *ngFor="let item of cortinascat">
        <div class="card h-100" (click)="verProd(item)" data-toggle="modal" data-target="#mdlProdCort">
          <div class="card-body text-center">
            <img [src]="item.imagen" width="100%" alt="">
            <img src="../../../assets/images/devid2.png" width="100%" alt="">
          </div>
        </div>     
      </div>  
    </div>
  </div>
  <div class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
    <div class="row">
      <div class="col-6 mb-4" *ngFor="let item of cortinascat">
        <div class="card h-100" (click)="verProd(item)" data-toggle="modal" data-target="#mdlProdCort">
          <div class="card-body text-center">
            <img [src]="item.imagen" width="100%" alt="">
            <img src="../../../assets/images/devid2.png" width="100%" alt="">
          </div>
        </div>     
      </div>  
    </div>
  </div>

  <div class="text-center">
    <br>
    <button  style="border-color: rgb(40, 37, 230) !important; border-radius: 15px; color: rgb(40, 37, 230) !important; font-size: 20px; width: 250px;height: 60px;" class="btn btn-outline-primary mb-4"><a [href]="catalogo" target="_blank"><b>VER</b> CATÁLOGO <i class="fas fa-long-arrow-alt-down"></i></a></button>
  </div>
</div>

<br><br>
<app-footer></app-footer>

<div class="modal fade" id="cotizar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cotizar <b>{{nombre | titlecase}}</b></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="solicitudForm">
          <h5><b>DATOS DEL PRODUCTO</b></h5>
          <hr>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Cantidad solicitada</label>
                  <input type="number" class="form-control" placeholder="Ingrese la cantidad del producto" formControlName="cantidad" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Color</label>
                  <input type="text" class="form-control" placeholder="color" formControlName="color" />
                </div>
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="enviarSolicitud(solicitudForm.value)" data-dismiss="modal">Enviar Solicitud</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div *ngIf="prodSel" class="modal fade" id="mdlProdCort" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar producto a solicitud de cotización:</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img [src]="prodSel.imagen" width="80%" alt="">
          <br>          
        </div>
        <form autocomplete="off" [formGroup]="solicitudForm">
          <h5><b>DATOS DEL PRODUCTO</b></h5>
          <hr>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Cantidad solicitada</label>
                  <input type="number" class="form-control" placeholder="Ingrese la cantidad" formControlName="cantidad" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Código Color</label>
                  <input type="number" class="form-control" placeholder="Código" formControlName="color" />
                  <p style="font-size: 9px;">Este código se encuentra en la ficha de producto, debe ingresar el código no el color</p>
                </div>
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="enviarSolicitud(solicitudForm.value)" data-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
