import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';
import { UsercontrolService } from 'src/app/services/usercontrol.service';

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {

  textoBusqueda = '';
  cotizaciones: any;
  cotSel: any;
  firma: any;
  nombre: any;
  constructor(private prodSvc: ProductosService,
              private router: Router,
              private userSvc: UsercontrolService) { }

  ngOnInit(): void {
    this.nombre = this.userSvc.usuarioLog.nombre;
    this.firma = this.userSvc.usuarioLog.firma;
    this.prodSvc.cargarCotizaciones()
                .subscribe(res => {
                  this.cotizaciones = res;
                });
  }

  printCoti(cotizacion: any){
    console.log(cotizacion); 
    this.cotSel = cotizacion;   
  }

  printPdfCoti(coti: any){
    this.prodSvc.pdf = coti;
    this.router.navigate(['pdf']);
  }

}
