import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  textoBusqueda = '';
  tarjeta = true;
  clientes: any;
  constructor(private prodSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {
    this.prodSvc.cargarClientes()
                .subscribe(res => {
                  this.clientes = res;
                })
  }

  cambioVisual(estado: string){
    if (estado === 'tabla') {
      this.tarjeta = false;
      console.log(this.tarjeta);
    }
    if (estado === 'tarjeta') {
      this.tarjeta = true;
      console.log(this.tarjeta);
    }
  }

  nuevaCotizacion(cliente: any){
    this.prodSvc.clienteSel = cliente;
    this.router.navigate(['/nueva']);
  }

}
