import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listasolicitudes',
  templateUrl: './listasolicitudes.component.html',
  styleUrls: ['./listasolicitudes.component.scss']
})
export class ListasolicitudesComponent implements OnInit {

  listaCortinas: any = [];
  client: any

  public solicitudForm = this.fb.group({
    empresa: ['', Validators.required],
    solicitante: ['', Validators.required],
    documento: ['', Validators.required],
    numero: ['', Validators.required],
    email: ['', Validators.required],
    telefono: ['', Validators.required],
    pais: ['', Validators.required]
  });
  constructor(private fb: FormBuilder,
              private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.loadStorage();
  }
  loadStorage(){   
    if (localStorage.getItem('dataCotizacionesNormarCortinas')) {
      this.listaCortinas = JSON.parse( localStorage.getItem('dataCotizacionesNormarCortinas'));
      console.log(this.listaCortinas);
    }
    if (localStorage.getItem('dataClienteCotizacionNormarCortinas')) {
      this.client = JSON.parse( localStorage.getItem('dataClienteCotizacionNormarCortinas'));
      this.solicitudForm = this.fb.group({
        empresa: [this.client.empresa, Validators.required],
        solicitante: [this.client.solicitante, Validators.required],
        documento: [this.client.documento, Validators.required],
        numero: [this.client.numero, Validators.required],
        email: [this.client.email, Validators.required],
        telefono: [this.client.telefono, Validators.required],
        pais: [this.client.pais, Validators.required]
      });
    }
   
  }

  borrarCortina(item: number){
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se eliminará este producto de la lista!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.listaCortinas.splice(item, 1);
        localStorage.setItem('dataCotizacionesNormarCortinas', JSON.stringify(this.listaCortinas));
        Swal.fire(
          'Eliminado!',
          'El producto ya no esta en tu lista de cotización.',
          'success'
        );
      }
    });
  }

  enviarSolicitudCortinas(solicitud){   
    solicitud.estado = 'enviada';
    solicitud.fecha = new Date().getTime();
    console.log(solicitud);
   let solicCotizacion = solicitud;
    solicCotizacion.productos =  this.listaCortinas;
    localStorage.setItem('dataClienteCotizacionNormarCortinas', JSON.stringify(solicitud));
    this.prodSvc.verificaCliente(solicitud.numero)
                .subscribe(res => {
                  if (res.length === 0) {
                    this.prodSvc.crearCliente(solicitud);                    
                  }                
                });
    this.prodSvc.solicitudesCortinas(solicCotizacion)
                .then(()=> {
                  Swal.fire('Solicitud enviada correctaente');
                  localStorage.removeItem('dataCotizacionesNormarCortinas');
                  // window.location.reload();
                });
  }
}
