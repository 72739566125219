import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nueva-cotizacion',
  templateUrl: './nueva-cotizacion.component.html',
  styleUrls: ['./nueva-cotizacion.component.scss']
})
export class NuevaCotizacionComponent implements OnInit {

  fap688: boolean;
  fap6880: boolean;

  cotDos = {
    cliente: '',
    identificacion: '',
    numero: '',
    email: '',
    telefono: '',
    productos: [],
    tipo: 'FAP 688-0',
    fecha: new Date().getTime(),
    usuario: 'User',
    subtotal: 0,
    iva: 0,
    total: 0
  }

  productosCot = [];

  public solicitudForm = this.fb.group({
    cliente: ['', Validators.required],
    tipo: ['FAP 688'],
    numero: ['', Validators.required],
    email: ['', Validators.required],
    telefono: ['', Validators.required],
    cantidad: ['', Validators.required],
    color: ['', Validators.required],
    articulo: ['', Validators.required],
    caracteristica: ['', Validators.required],
    material: ['', Validators.required],
    marcacion: ['', Validators.required],
    guaya: ['', Validators.required],
    longitud: ['', Validators.required],
    precio: ['', Validators.required],
    numeracion: ['', Validators.required],
    despacho: ['', Validators.required],
    pago: ['', Validators.required],
    garantia: ['', Validators.required],
    observaciones: ['', Validators.required],
    fecha: new Date().getTime(),
    subtotal: ['', Validators.required],
    iva: [''],
    total: ['', Validators.required],
    usuario: ['User']
  });

 public solicitudFormDos = this.fb.group({
    cantidad: ['', Validators.required],
    codigo: ['', Validators.required],
    producto: ['', Validators.required],
    unidad: ['', Validators.required]
  });

  liquidacion: number;
  subtotal: number;
  impuesto: number;
  cliente: any;
  sellos: any;

  prodSel: any;
  nombProdSel: any;
  imagenProdSel: any;
  fichaProdSel: any;

  constructor(private prodSvc: ProductosService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit(): void {
   // console.log(this.prodSvc.clienteSel);
    if (this.prodSvc.clienteSel) {
      this.cliente = this.prodSvc.clienteSel;
    }
    if (!this.prodSvc.clienteSel) {
      this.router.navigate(['/clientes']);
    }

    this.prodSvc.listaProductos()
    .subscribe(res => {
     this.sellos = res;
    })

  }

  seleccionSello(producto: any){
    this.prodSvc.cargarproductobyid(producto)
                .subscribe(res => {
                  this.prodSel = res;
                  console.log(this.prodSel);
                  this.nombProdSel = this.prodSel.producto;
                  this.imagenProdSel = this.prodSel.imagen; 
                  this.fichaProdSel = this.prodSel.fichaimg;               
                });
  }

  seleccionaCotizacion(cot: string){
    if (cot === '688') {
      this.fap688 = true;
      this.fap6880 = false;
    }
    if (cot === '6880') {
      this.fap688 = false;
      this.fap6880 = true;
    }
  }

  agregaProducto(producto: any){
    this.productosCot.push(producto);
    this.calcularCosto();
    this.reiniciarForm6880();
    console.log(this.productosCot);
  }

  calcularCosto(){
    let saldo = 0;
    for (const key in this.productosCot){
      saldo = saldo + (parseInt(this.productosCot[key].unidad) * parseInt(this.productosCot[key].cantidad));
    }
    this.subtotal = saldo;
    this.impuesto = (saldo * 19)/100;
    this.liquidacion = saldo + ((saldo * 19)/100);

  }

  borrarProducto(item: number){
    Swal.fire({
      title: 'Está seguro?',
      text: `Se eliminará este producto del listado!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo!'
    }).then(result => {
      if (result.value) {
        this.productosCot.splice(item, 1);
        this.calcularCosto();
        Swal.fire('Eliminado!', 'El producto ha sido borrado.', 'success');
      }
    });
  }

  reiniciarForm6880(){
   this.solicitudFormDos = this.fb.group({
      cantidad: [''],
      codigo: [''],
      producto: [''],
      unidad: ['']
    });
  }

  sendQuote(cotizacion: any){
    cotizacion.articulo = this.nombProdSel;
    cotizacion.cliente = this.cliente.nombre;
    cotizacion.email = this.cliente.email;
    cotizacion.telefono = this.cliente.telefono;
    cotizacion.numero = this.cliente.numero;
    cotizacion.subtotal = Number((cotizacion.precio * cotizacion.cantidad));
    cotizacion.iva = (cotizacion.subtotal * Number(cotizacion.iva))/100;
    cotizacion.total = cotizacion.subtotal + cotizacion.iva;
    cotizacion.imagen = this.imagenProdSel;
    cotizacion.ficha = this.fichaProdSel;
    console.log(cotizacion);
    this.prodSvc.crearCotizacion(cotizacion).then(() => {
      Swal.fire('cotizacion enviada correctamente');
      this.router.navigate(['/cotizaciones']);
    });
  }

  enviarCotizacion(){
    this.cotDos.cliente = this.cliente.nombre;
    this.cotDos.identificacion = this.cliente.documento;
    this.cotDos.numero = this.cliente.numero;
    this.cotDos.email = this.cliente.email;
    this.cotDos.telefono = this.cliente.telefono;
    this.cotDos.productos = this.productosCot;
    this.cotDos.subtotal = this.subtotal;
    this.cotDos.iva = this.impuesto;
    this.cotDos.total = this.liquidacion;
    console.log(this.cotDos);

    this.prodSvc.crearCotizacion(this.cotDos).then(() => {
      Swal.fire('cotizacion enviada correctamente');
      this.router.navigate(['/cotizaciones']);
    });
  }

}
