<app-navbar></app-navbar>
<div class="text-center sellos">
  <h1 style="z-index: 10; position: relative;">-SELLOS DE SEGURIDAD-</h1>

  <div class="container-fluid" style="margin-top: -20px;">
    <div class="container-fluid trjtas">
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4">
          <div class="card h-100 servicios sector">
            <img src="../../../assets/images/pivote.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
            <div class="card-footer">
              <div class="sctor">
                <div class="text-left">
                  <br><br>
                  <h1>Sector</h1>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('sector','energía')">
                    <img src="../../../assets/images/Sector/otros/energia.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Energía</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Energía</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('sector','gas')">
                    <img src="../../../assets/images/Sector/otros/gas.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Gas</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Gas</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('sector','acueducto')">
                    <img src="../../../assets/images/Sector/otros/acueducto.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Acueducto</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Acueducto</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('sector','transporte')">
                    <img src="../../../assets/images/Sector/otros/transporte.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Transporte</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Transporte</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('sector','agrícola')">
                    <img src="../../../assets/images/Sector/otros/agricola.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Agrícola</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Agrícola</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('sector','farmacéutico')">
                    <img src="../../../assets/images/Sector/otros/farmaceutico.svg" width="80%" class="ml-1" alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Farmacéutico</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Farmacéutico</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem">

                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('sector','informático')">
                    <img src="../../../assets/images/Sector/otros/informatico.svg" width="80%" class="ml-1"  alt="">
                    <p class="ml-1 movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Informático</p>
                    <p class="ml-1 full d-none d-lg-block d-xl-block ">Informático</p>
                  </div>
                  <div class="col-4 scItem">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card h-100 servicios tipoSello">
            <img src="../../../assets/images/pivote.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
            <div class="card-footer">
              <div class="sctor">
                <div class="text-left">
                  <br>
                  <h1>Tipo de sello</h1>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','ancla')">
                    <img src="../../../assets/images/tipoSello/ancla.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo Ancla</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo Ancla</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','rotor')">
                    <img src="../../../assets/images/tipoSello/rotor.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo rotor</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo rotor</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','candado')">
                    <img src="../../../assets/images/tipoSello/candado.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo Candado</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo Candado</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','botella')">
                    <img src="../../../assets/images/tipoSello/botella.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo botella</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo botella</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','tornillo')">
                    <img src="../../../assets/images/tipoSello/tornillo.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo tornillo</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo tornillo</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('tipo de sello','inserto')">
                    <img src="../../../assets/images/tipoSello/inserto.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Inserto metálico</p>
                    <p class="full d-none d-lg-block d-xl-block ">Inserto metálico</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-center scItem">

                  </div>
                  <div class="col-4 text-center scItem" (click)="irCatalogo('tipo de sello','correa')">
                    <img src="../../../assets/images/tipoSello/correa.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo correa</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo correa</p>
                  </div>
                  <div class="col-4 text-center scItem" (click)="irCatalogo('tipo de sello','chapa')">
                    <img src="../../../assets/images/tipoSello/chapa.svg" width="75%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tipo chapa</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tipo chapa</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card h-100 servicios aplicacion">
            <img src="../../../assets/images/pivote.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
            <div class="card-footer">
              <div class="text-center sctor">
                <div class="text-left">
                  <h1>Aplicación</h1>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','medidores')">
                    <img src="../../../assets/images/Aplicacion/medidores.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Medidores</p>
                    <p class="full d-none d-lg-block d-xl-block ">Medidores</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','contenedores')">
                    <img src="../../../assets/images/Aplicacion/contenedores.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Contenedores</p>
                    <p class="full d-none d-lg-block d-xl-block ">Contenedores</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','taxímetros')">
                    <img src="../../../assets/images/Aplicacion/taximetros.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Taxímetros</p>
                    <p class="full d-none d-lg-block d-xl-block ">Taxímetros</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','servidores')">
                    <img src="../../../assets/images/Aplicacion/servidores.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Servidores</p>
                    <p class="full d-none d-lg-block d-xl-block ">Servidores</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','tragamonedas')">
                    <img src="../../../assets/images/Aplicacion/tragamonedas.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Tragamonedas</p>
                    <p class="full d-none d-lg-block d-xl-block ">Tragamonedas</p>
                  </div>
                  <div class="col-4 scItem" (click)="irCatalogo('aplicación','cajas')">
                    <img src="../../../assets/images/Aplicacion/cajas.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Cajas</p>
                    <p class="full d-none d-lg-block d-xl-block ">Cajas</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-center scItem">

                  </div>
                  <div class="col-4 text-center scItem" (click)="irCatalogo('aplicación','dispensadores')">
                    <img src="../../../assets/images/Aplicacion/dispensadores.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Dispensadores Dinero y Snacks</p>
                    <p class="full d-none d-lg-block d-xl-block ">Dispensadores Dinero y Snacks</p>
                  </div>
                  <div class="col-4 text-center scItem" (click)="irCatalogo('aplicación','monederos')">
                    <img src="../../../assets/images/Aplicacion/telefonos.svg" width="80%" alt="">
                    <p class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">Teléfonos Monederos</p>
                    <p class="full d-none d-lg-block d-xl-block ">Teléfonos Monederos</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>
<app-footer></app-footer>
