<app-navbar></app-navbar>
<div class="container-fluid" style="margin-top: 8rem;">
  <div class="row">
    <div class="col-sm-7">
      <div class="card"  style="border: none">
        <div class="card-body">
          <div class="text-center">
            <img [src]="productDetail.imagenDetalle" class="img-fluid" alt="">
            <br>
            <div class="row">
              <div class="col-4">

              </div>
              <div class="col-4 text-rigth">
                <p style="font-size: 12px;">{{productDetail.disponibilidad}}</p>
              </div>
              <div class="col-4">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="card" style="border: none">
        <div class="card-body">
          <h1><b>{{productDetail.producto | titlecase}}</b> ({{productDetail.nombre | titlecase}})</h1>
          <p style="font-size: 13px;text-align: justify;">
            {{productDetail.descripcion}}
          </p>
          <img src="../../../assets/images/devider.png" width="100%" class="img-fluid" alt="">
          <br>
          <p style="font-size: 20px;">
            <b>Material</b><br>
            {{productDetail.material}}.
          </p>
          <p style="font-size: 20px;">
            <b>Aplicación</b><br>
            {{productDetail.aplicacion}}.
          </p>
          <p style="font-size: 20px;">
            <b>Marcación</b> {{productDetail.marcacion | titlecase}}
          </p>
          <p style="font-size: 20px;">
             {{productDetail.tipo | titlecase}}
          </p>
          <p style="font-size: 20px;">
            <b>Dimensiones (mm)</b><br>
            {{productDetail.dimensiones | titlecase}}
          </p>
          <br><br>
          <div class="row">
            <div class="col-6">
              <a [href]="productDetail.ficha"  target="_blank" style="border-color: #1d00ff !important; color: #1d00ff !important;" class="btn btn-outline-primary btn-block h-100">FICHA TÉCNICA <i class="fas fa-long-arrow-alt-down"></i></a>
            </div>
            <div class="col-6">
              <button class="btn btn-dark btn-block h-100" data-toggle="modal" data-target="#cotizar"> <img src="../../../assets/images/carretilla.png" width="40"  alt=""> Cotizar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>
<app-footer></app-footer>
<!-- Modal -->
<div class="modal fade" id="cotizar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cotizar <b>{{productDetail.producto | titlecase}}</b></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="solicitudForm">
          <h5><b>DATOS DEL SOLICITANTE</b></h5>
          <hr>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Empresa / Persona</label>
                <input type="text" class="form-control" placeholder="Ingrese la empresa o persona natural" formControlName="empresa" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Solicitante</label>
                <input type="text" class="form-control" placeholder="Persona que solicita la cotización" formControlName="solicitante" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="control-label">Tipo de documento:</label>
                <select class="form-control custom-select" formControlName="documento">
                  <option selected value="">Seleccione la su tipo de documento:</option>
                  <option value="Cédula ciudadanía">Cédula ciudadanía</option>
                  <option value="Cédula extranjería">Cédula extranjería</option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="NIT">NIT</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Número identificación</label>
                <input type="number" class="form-control" placeholder="Número documento" formControlName="numero" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Teléfono</label>
                <input type="number" class="form-control" placeholder="Número telefónico" formControlName="telefono" />
              </div>
            </div>
            <div class="col-8">
              <div class="form-group">
                <label>E-mail</label>
                <input type="email" class="form-control" placeholder="Correo electrónico" formControlName="email" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Pais</label>
                <input type="text" class="form-control" placeholder="Pais de envío" formControlName="pais" />
              </div>
            </div>
          </div>
          <h5><b>DATOS DEL PRODUCTO</b></h5>
          <hr>
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label>Cantidad solicitada</label>
                  <input type="number" class="form-control" placeholder="Ingrese la cantidad del producto" formControlName="cantidad"/>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Color</label>
                  <input type="text" class="form-control" placeholder="color" formControlName="color" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Longitud</label>
                  <input type="text" class="form-control" placeholder="Longitud de la guaya" formControlName="longitud" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Observaciones:</label>
                  <textarea class="form-control" rows="5" formControlName="observaciones"></textarea>
                </div>
              </div>
            </div>
            <div class="row">.
              <div class="col text-danger">
                <p *ngIf="campoNoValido('empresa')">* Debe ingresar la empresa</p>
                <p *ngIf="campoNoValido('pais')">* Debe indicar el pais</p>
                <p *ngIf="campoNoValido('solicitante')">* Ingrese el nombre completo del solicitante</p>
                <p *ngIf="campoNoValido('documento')">* Ingrese el tipo de documento</p>
                <p *ngIf="campoNoValido('numero')">* Ingrese el número de documento</p>
                <p *ngIf="campoNoValido('telefono')">* Ingrese el número de telefónico</p>
                <p *ngIf="campoNoValido('email')">* Ingrese correo electrónico</p>
                <p *ngIf="campoNoValido('cantidad')">* Debe indicar la cantidad de sellos</p>
                <p *ngIf="campoNoValido('color')">* Debe indicar el color</p>
                <p *ngIf="campoNoValido('longitud')">* Debe ingresar la longitud de la guaya</p>
               
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="enviarSolicitud(solicitudForm.value)" data-dismiss="modal">Enviar Solicitud</button>
      </div>
    </div>
  </div>
</div>
