<nav id="menu" class="navbar navbar-expand-lg navbar-light bg-light fixed-top" #stickyMenu [class.sticky]="sticky">
  <a class="navbar-brand" href="#">
   <img src="../../../assets/images/Logo.png" width="180" alt="">
 </a>
 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>

 <div class="collapse navbar-collapse" id="navbarSupportedContent">
   <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link" href="#">INICIO</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">SOMOS</a>
    </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SERVICIOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">EXPORTACIONES</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">NOTICIAS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">HABEAS DATA</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">POLÍTICA SIG</a>
     </li>
   </ul>
 </div>
</nav>

<div class="portada">
 <div class="contenido">
   <div class="text-center">
     <br><br><br><br><br><br>
     <h1 style="color: black;">- ESCOLARES -</h1>
   </div>
 </div>
</div>
<div class="container-fluid">
 <img src="../../../assets/images/escolar/sacapuntas.png" width="600" class="img-fluid" alt="">
</div>
<br>
<div class="container-fluid" style="max-width: 90%;">

<div class="publicaciones">
    <div class="container-fluid">
        <div class="col-md-12"><br>
            <!--Carousel Wrapper-->
            <div id="multi-item-example1" class="carousel slide carousel-multi-item" data-ride="carousel">
                <!--Controls-->
                <div class="controls-top">
                    <a class="btn-floating float-left" href="#multi-item-example1" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                    <a class="btn-floating float-right" href="#multi-item-example1" data-slide="next"><i class="fa fa-chevron-right"></i></a>
                </div>
                <!--/.Controls-->
                <!--Slides-->
                <div class="carousel-inner" role="listbox">

                    <!--First slide-->
                    <div class="carousel-item active">

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/escolar/sacapunta 1.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/escolar/sacapunta 2.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/escolar/sacapunta 3.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/escolar/sacapunta 4.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--/.First slide-->

                    <!--Second slide-->
                    <div class="carousel-item">

                      <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                              <img src="../../../assets/images/escolar/sacapunta 5.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                              <img src="../../../assets/images/escolar/sacapunta 7.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3" style="float:left">
                      <div class="card mb-2">
                          <div class="card-body">
                            <img src="../../../assets/images/escolar/sacapunta 9.png" class="img-fluid" alt="">
                          </div>
                      </div>
                  </div>

                  <div class="col-md-3" style="float:left">
                    <div class="card mb-2">
                        <div class="card-body">
                          <img src="../../../assets/images/escolar/sacapunta 10.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                    </div>
                    <!--/.Second slide-->
                </div>
                <!--/.Slides-->
            </div>
            <!--/.Carousel Wrapper-->
        </div>
    </div>
    <br>
</div>

<div class="publicaciones">
  <div class="container-fluid">
      <div class="col-md-12"><br>
          <!--Carousel Wrapper-->
          <div id="multi-item-example2" class="carousel slide carousel-multi-item" data-ride="carousel">
              <!--Controls-->
              <div class="controls-top">
                  <a class="btn-floating float-left" href="#multi-item-example2" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                  <a class="btn-floating float-right" href="#multi-item-example2" data-slide="next"><i class="fa fa-chevron-right"></i></a>
              </div>
              <!--/.Controls-->
              <!--Slides-->
              <div class="carousel-inner" role="listbox">

                  <!--First slide-->
                  <div class="carousel-item active">

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 12.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 13.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 19.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 20.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                  </div>
                  <!--/.First slide-->

                  <!--Second slide-->
                  <div class="carousel-item">

                    <div class="col-md-3" style="float:left">
                      <div class="card mb-2">
                          <div class="card-body">
                            <img src="../../../assets/images/escolar/sacapunta 21.png" class="img-fluid" alt="">
                          </div>
                      </div>
                  </div>

                  <div class="col-md-3" style="float:left">
                      <div class="card mb-2">
                          <div class="card-body">
                            <img src="../../../assets/images/escolar/sacapunta 22.png" class="img-fluid" alt="">
                          </div>
                      </div>
                  </div>

                  <div class="col-md-3" style="float:left">
                    <div class="card mb-2">
                        <div class="card-body">
                          <img src="../../../assets/images/escolar/sacapunta 23.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="col-md-3" style="float:left">
                  <div class="card mb-2">
                      <div class="card-body">
                        <img src="../../../assets/images/escolar/sacapunta 24.png" class="img-fluid" alt="">
                      </div>
                  </div>
              </div>

                  </div>
                  <!--/.Second slide-->
              </div>
              <!--/.Slides-->
          </div>
          <!--/.Carousel Wrapper-->
      </div>
  </div>
  <br>
</div>

<div class="publicaciones">
  <div class="container-fluid">
      <div class="col-md-12"><br>
          <!--Carousel Wrapper-->
          <div id="multi-item-example3" class="carousel slide carousel-multi-item" data-ride="carousel">
              <!--Controls-->
              <div class="controls-top">
                  <a class="btn-floating float-left" href="#multi-item-example3" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                  <a class="btn-floating float-right" href="#multi-item-example3" data-slide="next"><i class="fa fa-chevron-right"></i></a>
              </div>
              <!--/.Controls-->
              <!--Slides-->
              <div class="carousel-inner" role="listbox">

                  <!--First slide-->
                  <div class="carousel-item active">

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 25.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 28.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 29.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta 30.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                  </div>
                  <!--/.First slide-->

                  <!--Second slide-->
                  <div class="carousel-item">

                    <div class="col-md-3" style="float:left">
                      <div class="card mb-2">
                          <div class="card-body">
                            <img src="../../../assets/images/escolar/sacapunta 33.png" class="img-fluid" alt="">
                          </div>
                      </div>
                  </div>

                  <div class="col-md-3" style="float:left">
                      <div class="card mb-2">
                          <div class="card-body">
                            <img src="../../../assets/images/escolar/sacapunta 34.png" class="img-fluid" alt="">
                          </div>
                      </div>
                  </div>

                  <div class="col-md-3" style="float:left">
                    <div class="card mb-2">
                        <div class="card-body">
                          <img src="../../../assets/images/escolar/sacapunta 35.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>

                <div class="col-md-3" style="float:left">
                  <div class="card mb-2">
                      <div class="card-body">
                        <img src="../../../assets/images/escolar/sacapunta 36.png" class="img-fluid" alt="">
                      </div>
                  </div>
              </div>

                  </div>
                  <!--/.Second slide-->
              </div>
              <!--/.Slides-->
          </div>
          <!--/.Carousel Wrapper-->
      </div>
  </div>
  <br>
</div>

<div class="publicaciones">
  <div class="container-fluid">
      <div class="col-md-12"><br>
          <!--Carousel Wrapper-->
          <div id="multi-item-example4" class="carousel slide carousel-multi-item" data-ride="carousel">
              <!--Controls-->
              <div class="controls-top">
                  <a class="btn-floating float-left" href="#multi-item-example4" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                  <a class="btn-floating float-right" href="#multi-item-example4" data-slide="next"><i class="fa fa-chevron-right"></i></a>
              </div>
              <!--/.Controls-->
              <!--Slides-->
              <div class="carousel-inner" role="listbox">

                  <!--First slide-->
                  <div class="carousel-item active">

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta bubble.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta kass.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3" style="float:left">
                          <div class="card mb-2">
                              <div class="card-body">
                                <img src="../../../assets/images/escolar/sacapunta mimi.png" class="img-fluid" alt="">
                              </div>
                          </div>
                      </div>

                  </div>
                  <!--/.First slide-->
              </div>
              <!--/.Slides-->
          </div>
          <!--/.Carousel Wrapper-->
      </div>
  </div>
  <br>
</div>

  <div class="row row-cols-1 row-cols-md-5">

  </div>
</div>
<br>
<div class="container-fluid">
  <img src="../../../assets/images/escolar/flautas.png" width="900" class="img-fluid" alt="">
 </div>
 <div class="container-fluid" style="max-width: 80%;">
  <div class="row row-cols-1 row-cols-md-4">
    <div class="col mb-4">
      <div class="card h-100">
        <div class="card-body">
          <img src="../../../assets/images/escolar/porta2.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card h-100">
        <div class="card-body">
          <img src="../../../assets/images/escolar/porta5.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card h-100">
        <div class="card-body">
          <img src="../../../assets/images/escolar/metalizada.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card h-100">
        <div class="card-body">
          <img src="../../../assets/images/escolar/hamelin.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</div>
<app-foot-construccion></app-foot-construccion>
