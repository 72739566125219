import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {

  notic1: boolean;
  notic2: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  openNew(noticia: string){
    if (noticia === 'not1') {
      this.notic1 = true;
      this.notic2 = false;
    }
    if (noticia === 'not2') {
      this.notic1 = false;
      this.notic2 = true;
    }
  }

}
