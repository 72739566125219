import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle-cortinas',
  templateUrl: './detalle-cortinas.component.html',
  styleUrls: ['./detalle-cortinas.component.scss']
})
export class DetalleCortinasComponent implements OnInit {

  public formSubmitted = false;
  producto: any;
  nombre: string;
  catalogo: string;
  imagen: string;
  titulo: string;
  listaCortinas: any = [];
  idCatProd: any;

  cortinascat: any;
  prodSel: any;

  public solicitudForm = this.fb.group({
    tipo: ['Cortinas', Validators.required],
    cantidad: ['', Validators.required],
    color: ['', Validators.required],
    producto: ['', Validators.required],
    fecha: new Date().getTime()
  });
  constructor(private prodSvc: ProductosService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {       
    this.loadStorage();
    const id = this.route.snapshot.paramMap.get('id');
    this.idCatProd = id;
    this.cargarProducto(id);
  }

  loadStorage(){
    if (localStorage.getItem('dataCotizacionesNormarCortinas')) {
      this.listaCortinas = JSON.parse( localStorage.getItem('dataCotizacionesNormarCortinas'));
    }
  }

  campoNoValido(campo: string): boolean {
    if (this.solicitudForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  cargarProducto(idCort: string){
    this.prodSvc.cargarCortinasId(idCort)
                .subscribe(res => {
                  this.producto = res;
                  this.nombre = this.producto.nombre;
                  this.imagen = this.producto.imagen;
                  this.titulo = this.producto.titulo;
                  this.catalogo = this.producto.catalogo;
                  if (idCort === '2pSZAu6BNgN8HvvZFqWi') {
                    this.cortinascat= this.prodSvc.cortinas; 
                  }
                  if (idCort === 'moh1UBYlRqpU1tOfQ83e') {
                    this.cortinascat= this.prodSvc.panelJapones; 
                  }
                  if (idCort === '0kt3u5SmWk2RuVM8RrsM') {
                    this.cortinascat= this.prodSvc.miniPersianas; 
                  }
                  if (idCort === '359C6BiZf70bqlG1Rcdy') {
                    this.cortinascat= this.prodSvc.persianaEnrollable; 
                  }
                  if (idCort === 'xksHMYPByrQs4Rht23rO') {
                    this.cortinascat= this.prodSvc.persianaVertical; 
                  }
                  if (idCort === 'xT76RfrF584KMqt4q7U3') {
                    this.cortinascat= this.prodSvc.persianaRomana; 
                  }
                  if (idCort === 'acVxW7cU5BRVCHuojMR5') {
                    this.cortinascat= this.prodSvc.persianaSheer; 
                  }
                });
  }

  enviarSolicitud(solicitud: any){
    solicitud.producto = this.prodSel.nombre;
    this.listaCortinas.push(solicitud);
    console.log(this.listaCortinas);
    localStorage.setItem('dataCotizacionesNormarCortinas', JSON.stringify(this.listaCortinas));
    this.router.navigate(['/listasolicitudes']);
  }

  verProd(prod: any){
    console.log(prod);
    this.prodSel = prod;
  }



}
