<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <a class="navbar-brand" href="#">
    <img src="../../../assets/images/Logo.png" width="180" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">PRODUCTOS</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
          SOMOS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['/compañia']">Compañía</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/certificaciones']">Certificaciones</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/servicios']">SERVICIOS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/exportaciones']">EXPORTACIONES</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/noticias']">NOTICIAS</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
          HABEAS DATA
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FMANUAL-DE-POLITICAS-Y-PROCEDIENTOS-DE-PROTECCIN-DE-DATOS-PERSONALES.pdf?alt=media&token=6753b926-d6a8-44f8-9444-0bbc98e196b6">MANUAL DE POLÍTICAS Y PROCEDIMIENTOS DE PROTECCIÓN DE DATOS PERSONALES</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FFORMATO-RECLAMACIONES-PARA-TRATAMIENTO-DE-DATOS-PERSONALES.pdf?alt=media&token=ece77df6-2347-45cf-a4be-2bde24535c52">FORMATO DE RECLAMACIONES PARA TRATAMIENTOS DE DATOS PERSONALES</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
          POLÍTICA SIG
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FPOLITICA%20DE%20RESPONSABILIDAD%20SOCIAL.pdf?alt=media&token=3d768da2-713c-4b9b-9be6-018dc421f9d9">POLÍTICA DE RESPONSABILIDAD SOCIAL</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FPOLI%CC%81TICA%20NORMARH.pdf?alt=media&token=4f4daad7-6453-4c03-818d-34d7ca959cd0">POLITICA NORMARH</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FOBJETIVOS%20NORMARH.pdf?alt=media&token=02d5efac-f9b4-40f2-b6d3-0e6fd2180be2">OBJETIVOS NORMARH</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/documentos%2FREGLAMENTO%20DE%20HIGIENE%20Y%20SEGURIDAD%20INDUSTRIAL.pdf?alt=media&token=faab75ad-1a28-4edb-9b3b-5e5be60df495">REGLAMENTO DE HIGIENE Y SEGURIDAD INDUSTRIAL</a>
        </div>
      </li>
    </ul>
    <div class="text right">
      <img src="../../../assets/images/what.png" width="70" alt="" style="cursor: pointer;" (click)="whatsapp()">
    </div>
  </div>
</nav>
<div class="hr">
  <hr/>
</div>
