import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foot-construccion',
  templateUrl: './foot-construccion.component.html',
  styleUrls: ['./foot-construccion.component.scss']
})
export class FootConstruccionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
