import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import { UsercontrolService } from 'src/app/services/usercontrol.service';

@Component({
  selector: 'app-cotcortinas',
  templateUrl: './cotcortinas.component.html',
  styleUrls: ['./cotcortinas.component.scss']
})
export class CotcortinasComponent implements OnInit {

  textoBusqueda = '';
  lista: any;
  cotSel: any;
  flete = 0;
  liquidacion = 0;
  iva = 0;
  userData: any;
  constructor(private prodSvc: ProductosService,
              private userSvc: UsercontrolService) { }

  ngOnInit(): void {
    this.userData = this.userSvc.usuarioLog;
    console.log(this.userData);    
    this.prodSvc.cargarCotizacionesCortinas()
                .subscribe(res => {
                  this.lista = res;
                  console.log(this.lista);
                  
                });
  }

  printCoti(cotizacion: any){
    console.log(cotizacion); 
    this.cotSel = cotizacion; 
    this.iva = this.cotSel.totalcot * 0.19;  
    this.liquidacion =  Number(this.cotSel.totalcot) + Number(this.iva) + Number(this.flete);
  }

  whatsappcliente(cel: any){
    console.log(cel);  
    cel = '57'+cel;
    window.open(`https://wa.me/${cel}`, "_blank");  
  }

  valorFlete(flte: any){
    this.flete = flte.value;
    this.liquidacion = Number(this.cotSel.totalcot) + Number(this.iva) + Number(this.flete);
    
  }

}
