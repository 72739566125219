import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PortadaComponent } from './components/portada/portada.component';
import { FooterComponent } from './components/footer/footer.component';
import { SellosSeguridadComponent } from './pages/sellos-seguridad/sellos-seguridad.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { AngularFireModule } from '@angular/fire/';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { ProductoComponent } from './pages/producto/producto.component';
import { SolicitudesComponent } from './crm/solicitudes/solicitudes.component';
import { ClientesComponent } from './crm/clientes/clientes.component';
import { CotizacionesComponent } from './crm/cotizaciones/cotizaciones.component';
import { NavSecComponent } from './crm/nav-sec/nav-sec.component';
import { CortinasComponent } from './pages/cortinas/cortinas.component';
import { N8Component } from './pages/n8/n8.component';
import { N15Component } from './pages/n15/n15.component';
import { N16Component } from './pages/n16/n16.component';
import { DetalleCortinasComponent } from './pages/detalle-cortinas/detalle-cortinas.component';
import { ConstruccionComponent } from './pages/construccion/construccion.component';
import { EscolaresComponent } from './pages/escolares/escolares.component';
import { TocadiscosComponent } from './pages/tocadiscos/tocadiscos.component';
import { FootCortComponent } from './components/foot-cort/foot-cort.component';
import { FootConstruccionComponent } from './components/foot-construccion/foot-construccion.component';
import { DisenoProductosComponent } from './pages/diseno-productos/diseno-productos.component';
import { FooterDesarrolloComponent } from './components/footer-desarrollo/footer-desarrollo.component';
import { OtrosComponent } from './pages/otros/otros.component';
import { AeronauticaComponent } from './pages/aeronautica/aeronautica.component';
import { FilterPipe } from './pipes/filter.pipe';
import { NgxPrintModule } from 'ngx-print';
import { NuevaCotizacionComponent } from './crm/nueva-cotizacion/nueva-cotizacion.component';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { CertificacionesComponent } from './pages/certificaciones/certificaciones.component';
import { ServiComponent } from './pages/servi/servi.component';
import { ExportacionesComponent } from './pages/exportaciones/exportaciones.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { PoliticasigComponent } from './pages/politicasig/politicasig.component';
import { AdminComponent } from './crm/admin/admin.component';
import { ListasolicitudesComponent } from './pages/listasolicitudes/listasolicitudes.component';
import { SelloslistComponent } from './crm/selloslist/selloslist.component';
import { SolcortinasComponent } from './crm/solcortinas/solcortinas.component';
import { CotcortinasComponent } from './crm/cotcortinas/cotcortinas.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    NavbarComponent,
    PortadaComponent,
    FooterComponent,
    SellosSeguridadComponent,
    ProductosComponent,
    ProductoComponent,
    SolicitudesComponent,
    ClientesComponent,
    CotizacionesComponent,
    NavSecComponent,
    CortinasComponent,
    N8Component,
    N15Component,
    N16Component,
    DetalleCortinasComponent,
    ConstruccionComponent,
    EscolaresComponent,
    TocadiscosComponent,
    FootCortComponent,
    FootConstruccionComponent,
    DisenoProductosComponent,
    FooterDesarrolloComponent,
    OtrosComponent,
    AeronauticaComponent,
    FilterPipe,
    NuevaCotizacionComponent,
    EmpresaComponent,
    CertificacionesComponent,
    ServiComponent,
    ExportacionesComponent,
    NoticiasComponent,
    PoliticasigComponent,
    AdminComponent,
    ListasolicitudesComponent,
    SelloslistComponent,
    SolcortinasComponent,
    CotcortinasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
