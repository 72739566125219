<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 6rem;">
  <h3><img src="../../../assets/images/cotizaciones.png" width="60" alt=""> Listado de cotizaciones:</h3>
  <hr>
  <br>
  <div class="col-4">
    <input type="text" class="form-control inputPadding" placeholder="Buscar cotización por cliente..." [(ngModel)]="textoBusqueda">
  </div>
  <br>
  <div class="card mb-3" *ngFor="let item of cotizaciones | filter: textoBusqueda: 'cliente'">
    <div *ngIf="item.tipo != 'FAP 688-0'" class="card-body">
      <h5 class="card-title">Cotización enviada: - <small>{{item.fecha | date}}</small></h5>
      <p class="card-text">Cliente: <b>{{item.empresa | titlecase}}</b>.</p>
      <p class="card-text">Artículo: <b>{{item.articulo | titlecase}}</b>.</p>
      <p class="card-text">Descripción: <b>{{item.caracteristica | titlecase}}</b>.</p>
      <p class="card-text">Cantidad: <b>{{item.cantidad}}</b>.</p>
      <p class="card-text">Precio Unidad: <b>${{item.precio | number}}</b>.</p>
      <p class="card-text">Garantía: <b>{{item.garantia}}</b>.</p>
      <p class="card-text">Observaciones: <b>{{item.observaciones}}</b>.</p>
      <div class="text-right">
       <h4>Subtotal: <b>${{item.cantidad * item.precio | number}}</b></h4>
       <h4>IVA: <b>${{item.iva | number}}</b></h4>
       <h4>Total: <b>${{item.total | number}}</b></h4>
       <hr>
       <button class="btn btn-primary" (click)="printCoti(item)" data-toggle="modal" data-target="#exampleModal"><i class="fas fa-print fa-2x"></i></button>
      </div>
    </div>
    <div *ngIf="item.tipo == 'FAP 688-0'" class="card-body">
      <h5 class="card-title">Cotización enviada: - <small>{{item.fecha | date}}</small></h5>
      <p class="card-text">Cliente: <b>{{item.cliente | titlecase}}</b>.</p>
      <p class="card-text">Email: <b>{{item.email}}</b>.</p>
      <p class="card-text">Identificacion: <b>{{item.identificacion | titlecase}} - {{item.numero}}</b>.</p>
      <p class="card-text">Telefono: <b>{{item.telefono}}</b>.</p>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">Producto</th>
              <th scope="col">Código</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prd of item.productos">
              <th>{{prd.producto | titlecase}}</th>
              <td>{{prd.codigo}}</td>
              <td>{{prd.cantidad}}</td>
              <td>${{prd.unidad | number}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-right">
       <h4>Subtotal: <b>${{item.cantidad | number}}</b></h4>
       <h4>IVA: <b>${{item.iva | number}}</b></h4>
       <h4>Total: <b>${{item.total | number}}</b></h4>
       <hr>
       <button class="btn btn-primary mr-2" (click)="printCoti(item)" ><i class="fas fa-print fa-2x"></i></button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Imprimir Cotización</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>Se va a imprimir o guardar en PDF esta cotización</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" printSectionId="print-section" ngxPrint data-dismiss="modal">Imprimir</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="cotSel"  style="display: none;"  id="print-section">
    <div style="margin-left: 10%;margin-right: 10%;">
      <img src="../../../assets/images/headerCorti.jpeg" width="100%" alt="">
      <br>
      <h4><b>OFERTA ECONÓMICA</b></h4>
      <h5>Con mucho gusto cotizamos como sigue:</h5>
      
      <table class="prt" style="width: 100%;" border = "1">
        <tr>
          <td style="width: 30%;font-size: 12px;"><b>ARTÍCULO</b></td>         
          <td>
           <div class="text-center">
            <img [src]="cotSel.imagen" width="150" alt="">
            <br>
            {{cotSel.articulo}}
           </div>
          </td>
        </tr>
        
        <tr>
          <td style="font-size: 12px;" style="width: 30%;">COLOR</td>
          <td style="font-size: 12px;">{{cotSel.color}}</td>
        </tr>
        <tr>
          <td style="width: 30%;font-size: 12px;">CARACTERÍSTICA</td>
          <td style="font-size: 12px;">{{cotSel.caracteristica}}</td>
      </tr>
      
      <tr>
          <td style="width: 30%;font-size: 12px;">MATERIAL</td>
          <td>{{cotSel.material}}</td>
      </tr>
      <tr>
        <td style="width: 30%;font-size: 12px;">MARCACIÓN</td>
        <td style="font-size: 12px;">{{cotSel.marcacion}}</td>
    </tr>
    
    <tr>
        <td style="width: 30%;font-size: 12px;">LONGITUD DE GUAYA</td>
        <td style="font-size: 12px;">{{cotSel.longitud}}</td>
    </tr>
    <tr>
      <td style="width: 30%;font-size: 12px;">CANTIDAD</td>
      <td style="font-size: 12px;">{{cotSel.cantidad}}</td>
  </tr>
  
  <tr>
      <td style="width: 30%;font-size: 12px;">PRECIO</td>
      <td style="font-size: 12px;">{{cotSel.precio}}</td>
  </tr>
  <tr>
    <td style="width: 30%;font-size: 12px;">NUMERACIÓN</td>
    <td style="font-size: 12px;">{{cotSel.numeracion}}</td>
  </tr>

  <tr>
    <td style="width: 30%;font-size: 12px;">DESPACHO</td>
    <td style="font-size: 12px;">{{cotSel.despacho}}</td>
  </tr>
  <tr>
  <td style="width: 30%;font-size: 12px;">PAGO</td>
  <td style="font-size: 12px;">{{cotSel.pago}}</td>
  </tr>

  <tr>
  <td style="width: 30%;font-size: 12px;">GARANTÍA</td>
  <td style="font-size: 12px;">{{cotSel.garantia}}</td>
  </tr>
  <tr>
    <td style="width: 30%;font-size: 12px;">OBSERVACIONES</td>
    <td style="font-size: 12px;">{{cotSel.observaciones}}</td>
  </tr>
    </table>
    <table style="width: 100%;margin-top: 5px;" border = "1">
      <tr style="height: 50px;text-align: center; ">
        <td style="font-size: 12px;"><b>DESCRIPCIÓN</b></td>
        <td style="font-size: 12px;"><b>CANTIDAD</b></td>
        <td style="font-size: 12px;"><b>PRECIO UNITARIO SIN IVA</b></td>
        <td style="width: 20%;"><b>TOTAL</b></td>
      </tr>    
      <tr  style="height: 50px;text-align: center; ">
        <td style="font-size: 12px;">{{cotSel.articulo | uppercase}}</td>
        <td style="font-size: 12px;">{{cotSel.cantidad}}</td>
        <td style="font-size: 12px;">${{cotSel.precio | number}}</td>
        <td style="font-size: 12px;">${{cotSel.precio * cotSel.cantidad | number}}</td>
      </tr>
  </table>
  <table style="width: 100%;" border = "1">
    <tr>
      <td style="width: 80%;"><b>Valor del IVA</b></td>
      <td style="text-align: right;">${{cotSel.iva | number}}</td>
    </tr>
    <tr>
      <td style="width: 80%;"><b>Valor TOTAL</b></td>
      <td style="text-align: right;">${{cotSel.total | number}}</td>
  </tr>
  </table>
  <br>
  <h5 style="text-align: right;">FAP-687</h5>
  <img [src]="firma" width="150" alt="">
  <p>{{nombre | titlecase}}</p>
    </div>
    <div class="fichas">
      <br>
      <img [src]="cotSel.ficha" width="100%" alt="">
    </div>
</div>

