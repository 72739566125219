<app-navbar></app-navbar>

<div  style="background-color: rgb(12, 33, 67);color: #fff;">
  <br>
  <div class="container" style="margin-top: 8rem;">
    <div class="text-center" style="background-color: rgb(12, 33, 67);">
      <h1>- ACCESORIOS PARA CORTINAS - <br> Y PERSIANAS</h1>
    </div>
  </div>
  <br><br>
</div>
<div class="contcort d-none d-lg-block d-xl-block">
  <div class="prtCort">
    <div class="row">
      <div class="col-7 ml-5 mt-5">
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-5">
              <div class="card h-100 crd" [routerLink]="['/detalle/xksHMYPByrQs4Rht23rO']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Perciana-vertical.png" width="80%" alt="">
                </div>
              </div>
            </div>
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/0kt3u5SmWk2RuVM8RrsM']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Mini-persiana.png" width="80%" alt="">
                </div>
              </div>
            </div>       
          </div>
          <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/2pSZAu6BNgN8HvvZFqWi']" >
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Cortinas.png" width="80%" alt="">
                </div>
              </div>
            </div>
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/moh1UBYlRqpU1tOfQ83e']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/panel-japones.png" width="80%" alt="">
                </div>
              </div>
            </div> 
          </div>
          <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/xT76RfrF584KMqt4q7U3']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Persiana-romana.png" width="80%" alt="">
                </div>
              </div>
            </div>
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/acVxW7cU5BRVCHuojMR5']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Persiana-sheer.png" width="80%" alt="">
                </div>
              </div>
            </div>
            <div class="col mb-5 mt-2">
              <div class="card h-100 crd" [routerLink]="['/detalle/359C6BiZf70bqlG1Rcdy']">
                <div class="card-body">
                  <img src="../../../assets/images/cat-cortinas/Persiana-enrollable.png" width="80%" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5">
      </div>
    </div>
  </div>
</div>
<div class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
  <div class="container-fluid">
    <img src="../../../assets/images/cortinasMobil.png" width="100%" alt="">
  </div>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col-6 mb-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/xksHMYPByrQs4Rht23rO']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Perciana-vertical.png" width="80%" alt="">
              </div>
            </div>
          </div>
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/0kt3u5SmWk2RuVM8RrsM']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Mini-persiana.png" width="80%" alt="">
              </div>
            </div>
          </div>       
        </div>
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/2pSZAu6BNgN8HvvZFqWi']" >
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Cortinas.png" width="80%" alt="">
              </div>
            </div>
          </div>
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/moh1UBYlRqpU1tOfQ83e']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/panel-japones.png" width="80%" alt="">
              </div>
            </div>
          </div> 
        </div>
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/xT76RfrF584KMqt4q7U3']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Persiana-romana.png" width="80%" alt="">
              </div>
            </div>
          </div>
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/acVxW7cU5BRVCHuojMR5']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Persiana-sheer.png" width="80%" alt="">
              </div>
            </div>
          </div>
          <div class="col-6 mb-2 mt-2">
            <div class="card h-100 crd" [routerLink]="['/detalle/359C6BiZf70bqlG1Rcdy']">
              <div class="card-body">
                <img src="../../../assets/images/cat-cortinas/Persiana-enrollable.png" width="80%" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
  </div>
</div>



<br>  
<div class="container-fluid" style="background-color: rgb(12, 33, 67);max-width: 90%;">
  <div class="text-center">
    <a href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/catalogos%2FCata%CC%81logo%20CCP.pdf?alt=media&token=821c9b8e-b17b-4b4c-9dc7-28704aa4b147" target="_blank" class="btn btn-lg ctlgoBtn">CATÁLOGO <b>PDF</b> &nbsp;<i class="fas fa-long-arrow-alt-down"></i></a>
  </div>
  <br>
</div>

<div class="container">
  <br><br>
  <div class="row row-cols-1 row-cols-md-3">
    <div class="col mb-4">
      <div class="card h-100 escrt" [routerLink]="['/n8']">
        <div class="card-body">
          <img src="../../../assets/images/n8.png" class="img-fluid" width="80%" alt="...">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card h-100 escrt" [routerLink]="['/n15']">
        <div class="card-body">
          <img src="../../../assets/images/N15.png" class="img-fluid" width="80%" alt="...">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card h-100 escrt" [routerLink]="['/n16']">
        <div class="card-body">
          <img src="../../../assets/images/N16.png" class="img-fluid" width="80%" alt="...">
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>

<app-footer></app-footer>



