import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';

@Component({
  selector: 'app-sellos-seguridad',
  templateUrl: './sellos-seguridad.component.html',
  styleUrls: ['./sellos-seguridad.component.scss']
})
export class SellosSeguridadComponent implements OnInit {

  constructor(private router: Router,
              private productSvc: ProductosService) { }

  ngOnInit(): void {
  }

  irCatalogo(categoria: string, subcategoria: string){
    this.productSvc.categoria = categoria;
    this.productSvc.subcategoria = subcategoria;
    this.router.navigate(['/productos']);
  }

}
