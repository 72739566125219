<app-nav-sec></app-nav-sec>
<div class="container-fluid" style="margin-top: 6rem;">
  <h3><img src="../../../assets/images/request.png" width="60" alt=""> Solicitudes Cortinas:</h3>
  <hr>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Cliente</th>
          <th scope="col">Solicitante</th>
          <th scope="col">Fecha</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Identificación</th>
          <th scope="col">Email</th>
          <th scope="col">País</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listado">
          <td>{{item.empresa | titlecase}}</td>
          <td>{{item.solicitante | titlecase}}</td>
          <td>{{item.productos[0].fecha | date: 'medium'}}</td>
          <td>{{item.telefono}}</td>
          <td>{{item.numero}}</td>
          <td>{{item.email}}</td>
          <td>{{item.pais | titlecase}}</td>
          <td>
            <button class="btn btn-light" title="Ver detalle" (click)="detailRequest(item)" data-toggle="modal" data-target="#detailSolicitud"><i class="fas fa-th-list"></i></button>
            <button class="btn btn-light" title="Rechazar" (click)="eliminaSolicitud(item)"><i class="fas fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- Modal -->
<div *ngIf="solicitudSel" class="modal fade" id="detailSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detalle de la solicitud</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>Datos del cliente:</h4>
        <hr>
        <h5>Empresa: <b>{{solicitudSel.empresa | titlecase}}</b></h5>
        <h5>Solicitante: <b>{{solicitudSel.solicitante | titlecase}}</b></h5>
        <h5>Teléfono: <b>{{solicitudSel.telefono}}</b></h5>
        <h5>Email: <b>{{solicitudSel.email}}</b></h5>
        <h5>Fecha de envío: <b>{{solicitudSel.productos[0].fecha | date: 'medium'}}</b></h5>
        <h5>Pais: <b>{{solicitudSel.pais | titlecase}}</b></h5>
        <hr>
        <h4>Datos de productos:</h4>
        <hr>
        <table class="table table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th>Producto</th>
              <th>Tipo</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of solicitudSel.productos; let i = index">
              <td>{{item.producto}}</td>
              <td>{{item.tipo}}</td>
              <td>{{item.color}}</td>  
              <td>{{item.cantidad}}</td>           
              <td>
                <button *ngIf="!item.atendido" class="btn btn-success" (click)="actualizaProdCotizacion(i, item.producto)" data-toggle="modal" data-target="#mdlDataCot"><i class="fas fa-check"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="cotizar(solicitudSel)" data-toggle="modal" data-target="#cotizarSolicitud">Generar Cotización</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div *ngIf="prodsel" class="modal fade" id="mdlDataCot" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Asignar datos a productos de la solicitud:</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4><b>{{prodsel | titlecase}}</b></h4>
        <input type="text" class="form-control" placeholder="Código del producto" [(ngModel)]="codigo">
        <br>
        <input type="number" class="form-control" placeholder="Cantidad" [(ngModel)]="cantidad">
        <br>
        <input type="number" class="form-control" placeholder="Precio Unidad" [(ngModel)]="precioUni">
        <br>
        <textarea class="form-control" name="" id="" rows="5" [(ngModel)]="descripcion" placeholder="Observación"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#mdlprevioCot"><i class="fas fa-eye"></i></button>
        <button type="button" class="btn btn-primary" (click)="asignaDatos()" data-dismiss="modal">Asignar</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="solicitudSel" class="modal fade" id="mdlprevioCot" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
           <br>
           <img src="../../../assets/images/headerCorti.jpeg" width="100%" alt="">
           <div class="text-right">
            <p>FAP 688</p>
           </div>
           <div class="text-center cbz">
            <h3>COTIZACIÓN</h3>
           </div>
           <div class="cbz">
            <h4 class="ml-3 mt-2"><b>FORMATO PEDIDO NACIONAL - PEDIDO ONLINE</b></h4>
           </div>
           <br><br>
           Fecha: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{fecha | date: 'medium'}}
           <br>
           Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{solicitudSel.solicitante | titlecase}} - {{solicitudSel.empresa | uppercase}}
           <br><br>
           <table class="table table-hover table-sm table-bordered">
            <thead style="background-color: rgb(200, 200, 200);">
              <tr>
                <th scope="col">CÓDIGO DE PRODUCTO</th>
                <th scope="col">PRODUCTO</th>
                <th scope="col">CANTIDAD</th>
                <th scope="col">OBSERVACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of solicitudSel.productos">
                <td>{{item.codigo}}</td>
                <td>{{item.producto}}</td>
                <td>{{item.cantidad}}</td>
                <td>{{item.observacion}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</div>
</div>