<nav id="menu" class="navbar navbar-expand-lg navbar-light bg-light fixed-top" #stickyMenu [class.sticky]="sticky">
  <a class="navbar-brand" href="#">
   <img src="../../../assets/images/Logo.png" width="180" alt="">
 </a>
 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>

 <div class="collapse navbar-collapse" id="navbarSupportedContent">
   <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link" href="#">INICIO</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">SOMOS</a>
    </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SERVICIOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">EXPORTACIONES</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">NOTICIAS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">HABEAS DATA</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">POLÍTICA SIG</a>
     </li>
   </ul>
 </div>
</nav>

<div class="portada">
 <div class="contenido">
   <div class="text-center">
     <br><br><br><br><br><br>
     <h1>- AGUJAS PARA TOCADISCOS -</h1>
   </div>
 </div>
</div>
<br><br>
<div class="container">
 <div class="text-center">
   <br>
   <img  src="../../../assets/images/tocadiscos/titulo.png" class="img-fluid" width="100%" alt="">
   <br>
 </div>
 <br>
</div>
<div class="container-fluid" style="max-width: 90%;">
  <div>
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/images/tocadiscos/slide1.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/images/tocadiscos/slide2.png" class="d-block w-100" alt="...">
        </div>
      </div>
     <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev" style="background-color: rgba(0, 0, 0, 0) !important;border: none;">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next" style="background-color: rgba(0, 0, 0, 0) !important;border: none;">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </button>
    </div>
   </div>
</div>
<br><br>
<div class="ctlgo">
  <div class="cnt">
    <div class="container-fluid" style="max-width: 80%;">
      <img src="../../../assets/images/tocadiscos/catalogoToca.png" style="margin-top: 20rem;" width="100%" alt="">
      <br><br>
      <div class="text-center">
        <br><br><br>
        <button class="btn btn-outline-primary btn8"><a href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/catalogos%2F5.%20CATA%CC%81LOGO%20AGUJAS%2018%3A03%3A2020.pdf?alt=media&token=ed57a530-713c-4a88-b617-1325811d3dad" target="_blank">CATÁLOGO <b>PDF</b> <i class="fas fa-long-arrow-alt-down"></i></a></button>
      </div>
    </div>
  </div>
</div>

<br>
<app-foot-cort></app-foot-cort>
