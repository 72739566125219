<app-navbar></app-navbar>
<div class="fondo">
  <img src="../../../assets/images/noticias/fondo noticias.png" width="100%" alt="">
  <br><br>
  <div class="text-center">
    <img src="../../../assets/images/noticias/title.png" width="70%" alt="">
  </div>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/images/noticias/slide1.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/noticias/slide2.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/noticias/slide3.png" class="d-block w-100" alt="...">
      </div>
    </div>
   <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </div>
  <br><br>
  <div id="carouselExampleControls2" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/images/noticias/slide2-1.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/noticias/slide2-2.png" class="d-block w-100" alt="...">
      </div>
    </div>
   <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls2" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls2" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </div>
  <br><br>
  <div id="carouselExampleControls3" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/images/noticias/slide3-1.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/noticias/slide3-2.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/noticias/slide3-3.png" class="d-block w-100" alt="...">
      </div>
    </div>
   <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls3" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls3" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </div>
</div>
<br><br>
<app-footer></app-footer>
