<app-navbar></app-navbar>
<div class="container-fluid" style="margin-top: 6rem;">
  <div class="card">
    <div class="card-body titulo">
      <div class="text-center ">
        <h3>CERTIFICACIONES</h3>
      </div>
    </div>
  </div>
  <br>
  <div class="row row-cols-1 row-cols-md-2">
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
          <img src="../../../assets/images/web/CERTIFICADO2 BASC 2021.png" width="100%" alt="">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
          <img src="../../../assets/images/web/CERTIFICADO1 BASC 2021.png" width="100%" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-md-2">
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
          <img src="../../../assets/images/web/certifBasc.png" width="100%" alt="">
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
