<app-navbar></app-navbar>
<div class="portada">
  <div class="contenido">
    <div class="container-fluid d-none d-lg-block d-xl-block">
      <br>
      <p class="titulo1">Moldeamos soluciones,</p>
      <p class="titulo2">inyectamos progreso.</p>
    </div>
    <div class="container-fluid d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
      <br>
      <h2 class="mvl">Moldeamos soluciones,</h2>
      <h3 class="mvl">&nbsp;&nbsp; <b>inyectamos progreso.</b></h3>
    </div>
    <div class="container-fluid trjtas">
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4">
          <div class="card h-100 servicios" [routerLink]="['/escolar']">
            <img src="../../../assets/images/diseña/tar2.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
        <div class="col mb-4 mt-5">
          <div class="card h-100 servicios" [routerLink]="['/construccion']">
            <img src="../../../assets/images/diseña/tar1.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card h-100 servicios" [routerLink]="['/aeronautica']">
            <img src="../../../assets/images/diseña/tar3.png" class="card-img-top" alt="...">
            <div class="card-body">

            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</div>
<app-foot-construccion></app-foot-construccion>

