
<div class="row">
  <div class="col-sm-8">
    <div class="card">
      <img src="../../../assets/images/imagenLogin.jpeg" class="card-img-top" alt="...">
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card">
      <div class="card-body">
       <div class="text-center">
        <br><br>
        <img src="../../../assets/images/Logo.png" width="70%" alt="">
        <br><br><br>
        <h3>Inicio Sesión</h3>
       </div>
       <label>Usuario:</label>
       <select class="form-control" (change)="userSelect($event)">
        <option selected value="">Seleccione su Usuario</option>
        <option *ngFor="let item of usuarios" [value]="item.pass">{{item.nombre}}</option>
       </select>
       <br>
       <label>E-mail:</label>
       <input type="text" class="form-control" placeholder="Correo electrónico" [(ngModel)]="mail">
       <br>
       <label>Contraseña:</label>
       <input type="password" class="form-control" placeholder="Password" [(ngModel)]="passin">
       <br>
       <button class="btn btn-block" (click)="ingresar()">Ingresar</button>
      </div>
    </div>
  </div>
</div>
