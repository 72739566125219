<nav id="menu" class="navbar navbar-expand-lg navbar-light bg-light fixed-top" #stickyMenu [class.sticky]="sticky">
  <a class="navbar-brand" href="#">
   <img src="../../../assets/images/Logo.png" width="180" alt="">
 </a>
 <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>

 <div class="collapse navbar-collapse" id="navbarSupportedContent">
   <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link" href="#">INICIO</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">SOMOS</a>
    </li>
     <li class="nav-item">
       <a class="nav-link" href="#">SERVICIOS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">EXPORTACIONES</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">NOTICIAS</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">HABEAS DATA</a>
     </li>
     <li class="nav-item">
       <a class="nav-link" href="#">POLÍTICA SIG</a>
     </li>
   </ul>
 </div>
</nav>

<div class="portada">
 <div class="contenido">
   <div class="text-center">
     <br><br><br><br><br><br>
     <h1 style="color: black;">- CONSTRUCCIÓN -</h1>
   </div>
 </div>
</div>
<div class="container-fluid">
 <img src="../../../assets/images/chazos.png" width="400" class="img-fluid" alt="">
</div>
<br>
<div class="container-fluid" style="max-width: 90%;">
<div class="publicaciones">
    <div class="container-fluid">
        <div class="col-md-12"><br>
            <!--Carousel Wrapper-->
            <div id="multi-item-example1" class="carousel slide carousel-multi-item" data-ride="carousel">
                <!--Controls-->
                <div class="controls-top">
                    <a class="btn-floating float-left" href="#multi-item-example1" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                    <a class="btn-floating float-right" href="#multi-item-example1" data-slide="next"><i class="fa fa-chevron-right"></i></a>
                </div>
                <!--/.Controls-->
                <!--Slides-->
                <div class="carousel-inner" role="listbox">

                    <!--First slide-->
                    <div class="carousel-item active">

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                   <img src="../../../assets/images/chazos/chazo-tipo2.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                   <img src="../../../assets/images/chazos/chazo-tipo6.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                   <img src="../../../assets/images/chazos/chazo-tipo4.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                   <img src="../../../assets/images/chazos/chazo-cod.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--/.First slide-->

                    <!--Second slide-->
                    <div class="carousel-item">

                      <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                               <img src="../../../assets/images/chazos/chazo-tipo ancla.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                               <img src="../../../assets/images/chazos/chazo-drywall.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    </div>
                    <!--/.Second slide-->
                </div>
                <!--/.Slides-->
            </div>
            <!--/.Carousel Wrapper-->
        </div>
    </div>
    <br>
</div>
  <div class="text-center">
    <button  style="border-color: rgb(40, 37, 230) !important; border-radius: 15px; color: rgb(40, 37, 230) !important; font-size: 20px; width: 250px;height: 60px;" class="btn btn-outline-primary mb-4"><a href="https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/catalogos%2FChazos%20Normarh.pdf?alt=media&token=c84a6de1-33c9-40c1-8885-b6dce25a8922" target="_blank"><b>VER</b> CATÁLOGO <i class="fas fa-long-arrow-alt-down"></i></a></button>
  </div>
</div>
<div class="container-fluid">
  <img src="../../../assets/images/otros.png" width="400" class="img-fluid" alt="">
 </div>

 <div class="container-fluid" style="max-width: 90%;">
  <div class="publicaciones">
    <div class="container-fluid">
        <div class="col-md-12"><br>
            <!--Carousel Wrapper-->
            <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">

                <!--Controls-->
                <div class="controls-top">
                    <a class="btn-floating float-left" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                    <a class="btn-floating float-right" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
                </div>
                <!--/.Controls-->
                <!--Slides-->
                <div class="carousel-inner" role="listbox">

                    <!--First slide-->
                    <div class="carousel-item active">

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/otros/enchufe-n1.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/otros/enchufe-n3.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/otros/switche-aereo.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" style="float:left">
                            <div class="card mb-2">
                                <div class="card-body">
                                  <img src="../../../assets/images/otros/switche-vaiven.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--/.First slide-->

                    <!--Second slide-->
                    <div class="carousel-item">

                      <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                              <img src="../../../assets/images/otros/amarra-reutilizable.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3" style="float:left">
                        <div class="card mb-2">
                            <div class="card-body">
                              <img src="../../../assets/images/otros/correas-de-amarre.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    </div>
                    <!--/.Second slide-->
                </div>
                <!--/.Slides-->
            </div>
            <!--/.Carousel Wrapper-->
        </div>
    </div>
    <br>
  </div>

</div>
<app-foot-construccion></app-foot-construccion>

